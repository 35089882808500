import SuperFetch from '../api/SuperFetch';
import querystring from 'query-string';

class Voucher {
  getVoucherRedeems(query) {
    return SuperFetch(`voucher/orders?${querystring.stringify(query)}`);
  }
  getVoucherHistory(query) {
    return SuperFetch(`voucher/summery?${querystring.stringify(query)}`);
  }
  acceptVoucherRedeem(id) {
    return SuperFetch(`voucher/accept?order_id=${id}`, {
      method: 'POST',
    });
  }
  getStatistics() {
    return SuperFetch(`merchant/billclaim/statistics`);
  }
}

export default new Voucher();
