import React from 'react';

const SvgSwitchAccount = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="fill">
      <path d="M108.58 233.61c-58.06 0-105.3-47.23-105.3-105.29s47.24-105.3 105.3-105.3 105.29 47.24 105.29 105.3-47.22 105.29-105.29 105.29zm0-179.01c-40.65 0-73.71 33.06-73.71 73.71 0 40.64 33.06 73.71 73.71 73.71 40.64 0 73.71-33.06 73.71-73.71 0-40.65-33.06-73.71-73.71-73.71zm0 0" />
      <path d="M445.54 186.22H187.56c-8.72 0-15.79-7.08-15.79-15.79 0-8.72 7.08-15.79 15.79-15.79h257.98c17.42 0 31.59-14.17 31.59-31.59 0-17.41-14.17-31.59-31.59-31.59H181.03c-8.72 0-15.79-7.08-15.79-15.79 0-8.72 7.08-15.79 15.79-15.79h264.51c34.83 0 63.18 28.35 63.18 63.18s-28.35 63.16-63.18 63.16zm0 0M403.42 486.32c-58.06 0-105.29-47.23-105.29-105.3 0-58.06 47.23-105.3 105.29-105.3s105.3 47.24 105.3 105.3c-.01 58.07-47.24 105.3-105.3 105.3zm0-179c-40.64 0-73.71 33.06-73.71 73.71 0 40.64 33.06 73.71 73.71 73.71 40.65 0 73.71-33.06 73.71-73.71 0-40.65-33.07-73.71-73.71-73.71zm0 0" />
      <path d="M324.44 438.94H66.46c-34.83 0-63.18-28.35-63.18-63.18s28.35-63.18 63.18-63.18h264.51c8.72 0 15.79 7.08 15.79 15.79s-7.08 15.79-15.79 15.79H66.46c-17.42 0-31.59 14.17-31.59 31.59s14.17 31.59 31.59 31.59h257.98c8.72 0 15.79 7.08 15.79 15.79.01 8.73-7.07 15.81-15.79 15.81zm0 0" />
    </g>
  </svg>
);

export default SvgSwitchAccount;
