import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ContentLoader from 'react-content-loader';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minWidth: 200,
    height: 145,
    justifyContent: 'space-between',
    // boxShadow: '2px 5px 6px rgba(0, 0, 0, 0.2)',
  },
  content: {
    // flex: '1 0 auto',
    flexGrow: 1,
  },
  primaryText: {
    fontWeight: 'bold',
  },
  avatar: {
    margin: 10,
    marginBottom: 50,
    width: 60,
    height: 60,
  },
}));

export default function AvatarsCard({ primaryText, avatars, loading }) {
  const classes = useStyles();

  if (loading) {
    return (
      <ContentLoader
        height={160}
        width={400}
        speed={1}
        primaryColor="#ffffff"
        secondaryColor="#c3e5ff"
      >
        <rect x="119" y="17" rx="3" ry="3" width="145" height="24" />
        <circle cx="71" cy="96" r="32" />
        <circle cx="157" cy="83" r="1" />
        <circle cx="161" cy="93" r="32" />
        <circle cx="248" cy="94" r="32" />
        <circle cx="333" cy="93" r="32" />
      </ContentLoader>
    );
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" gutterBottom align="center">
          {primaryText}
        </Typography>
        <Grid container justify="center" alignItems="center">
          {avatars.length ? (
            avatars.map(av => <Avatar key={av} alt="User" src={av} className={classes.avatar} />)
          ) : (
            <Typography align="center" variant="subtitle1" gutterBottom>
              No New Customers Found!
            </Typography>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
