import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
// import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

// import smallAvatar from '../../assets/avatar.png';
// import selfie from '../../assets/selfie.png';

import ImageModal from '../../components/ImageModal';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
    '& > $column': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tableBody: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #D8D8D8',
    padding: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  column: {
    // flexGrow: 1,
    width: '100%',
    display: 'flex',
    marginRight: '1rem',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    fontWeight: 'bold',
  },
  link: {
    textAlign: 'center',
    color: '#2196F3',
    fontWeight: 'bold',
  },
  billImage: {
    width: '100px',
    height: '120px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '200px',
    },
    '& > img': {
      width: '100%',
      height: '80%',
      objectFit: 'cover',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function CashbackClaimTable({ data, loading }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  function handleClickOpen(e, url) {
    e.preventDefault();
    setImageUrl(url);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  if (loading) {
    return (
      <ContentLoader
        height={300}
        width={720}
        speed={1}
        primaryColor="#ffffff"
        secondaryColor="#c3e5ff"
      >
        <rect x="189" y="24" rx="0" ry="0" width="0" height="0" />
        <circle cx="47" cy="53" r="28" />
        <circle cx="64" cy="68" r="1" />
        <circle cx="52" cy="62" r="1" />
        <rect x="82" y="27" rx="0" ry="0" width="99" height="18" />
        <rect x="121" y="38" rx="0" ry="0" width="0" height="0" />
        <rect x="83" y="55" rx="0" ry="0" width="56" height="11" />
        <rect x="199" y="24" rx="0" ry="0" width="106" height="67" />
        <rect x="330" y="25" rx="0" ry="0" width="98" height="18" />
        <rect x="473" y="24" rx="0" ry="0" width="76" height="19" />
        <rect x="613" y="23" rx="0" ry="0" width="80" height="18" />
        <rect x="200" y="118" rx="0" ry="0" width="106" height="67" />
        <circle cx="49" cy="150" r="28" />
        <rect x="82" y="127" rx="0" ry="0" width="99" height="18" />
        <rect x="86" y="154" rx="0" ry="0" width="56" height="11" />
        <rect x="329" y="120" rx="0" ry="0" width="98" height="18" />
        <rect x="471" y="119" rx="0" ry="0" width="76" height="19" />
        <rect x="610" y="117" rx="0" ry="0" width="80" height="18" />
      </ContentLoader>
    );
  }

  return (
    <div>
      <ImageModal src={imageUrl} open={open} handleClose={handleClose} />
      <Paper className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Happi Point Customer List
        </Typography>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div className={classes.column}>Name</div>
            <div className={classes.column}>Images</div>
            <div className={classes.column}>Bill Amount</div>
            {/* <div className={classes.column}>Bill Type</div> */}
            <div className={classes.column}>Happi Points Paid</div>
          </div>
          {data.length ? (
            data.map(item => (
              <div className={classes.tableBody} key={item.id}>
                <div className={classes.column}>
                  <Avatar
                    alt={item.customer.name}
                    src={item.customer.profile_picture}
                    className={classes.avatar}
                  />
                  <div className={classes.name}>
                    <Typography variant="body1" gutterBottom>
                      {item.customer.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {format(parseISO(item.created_at), 'do LLL, yyyy')}
                    </Typography>
                  </div>
                </div>
                <div className={classes.column}>
                  <div className={classes.billImage}>
                    <img
                      src={item.image}
                      alt="Selfie"
                      onClick={e => handleClickOpen(e, item.image)}
                    />
                    <MaterialLink
                      href="#"
                      onClick={e => handleClickOpen(e, item.image)}
                      className={classes.link}
                    >
                      See Bill Image
                    </MaterialLink>
                  </div>
                </div>
                <div className={classes.column}>
                  <Typography variant="body1" gutterBottom>
                    ৳{item.amount}
                  </Typography>
                </div>
                {/* <div className={classes.column}>
                  <Typography variant="body1" gutterBottom>
                    {item.bill_type.toUpperCase()}
                  </Typography>
                </div> */}
                <div className={classes.column}>
                  <Typography variant="body1" gutterBottom>
                    ৳{item.discount} ({item.discount_percent}%)
                  </Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography align="center" variant="subtitle1" gutterBottom>
              No Happi Points Found!
            </Typography>
          )}
          {/* <div className={classes.link}>
            <Link className={classes.link} to="/cashback-claim">
              See All
            </Link>
          </div> */}
        </div>
      </Paper>
    </div>
  );
}
