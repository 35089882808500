import { baseUrl } from './apiConfig';
import { getAuth, clearAuth } from './auth';
import axios from 'axios';

/**
 * Maps queries to REST API
 *
 * @param {string} url Request url with query, e.g orders?page=1
 * @param {Object} optionProps fetch options, e.g. { method: 'POST', headers: { 'Authorization': 'token' } }
 * @returns {Promise} the Promise for json
 */

export default async (url, optionsProps = {}, fileUpload) => {
  const { id, token } = getAuth(); //TODO: Add auth token later

  const options = {
    method: 'GET',
    mode: 'cors',
    ...optionsProps,
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token, //TODO: Add auth token later
      id: id,
      'X-Requested-With': 'XMLHttpRequest',
      ...optionsProps.headers,
    }),
  };

  if (fileUpload) {
    delete options.headers['Content-Type'];
  }

  try {
    const response = await fetch(`${baseUrl}/${url}`, options);
    if (response.status === 401) {
      clearAuth();
    }
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  } catch (error) {
    console.log('---AUTH ERROR---', error);
    return error;
  }
};

export const SuperUploader = async (url, data) => {
  const { id, token } = getAuth();

  var config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      console.log(percentCompleted);
      // console.log(progressCb);
    },
    headers: {
      Accept: 'application/json',
      Authorization: token,
      id: id,
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  try {
    const response = await axios.post(`${baseUrl}/${url}`, data, config);
    if (response.statusText !== 'OK') {
      throw Error(response.statusText);
    }
    return response.data;
  } catch (error) {
    console.log('---AUTH ERROR---', error);
    return error;
  }
};
