import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';

import BusinessSummary from './BusinessSummary';
// import BillingDetails from './BillingDetails';
// import CashbackClaim from './CashbackClaim';
import KeyAnalytics from './KeyAnalytics';
import PromoteBusiness from './PromoteBusiness';
import ReferralIncome from './ReferralIncome';
import Review from './Review';
import SingleReview from './SingleReview';
import SwitchAccount from './SwitchAccount';
import Menu from './Menu';
// import UploadMenu from './UploadMenu';
import EditMenu from './EditMenu';
import VoucherIncome from './VoucherIncome';
import Profile from './Profile';
import NotificationDrawer from 'components/NotificationDrawer';
import { EchoProvider } from 'lib/EchoContext';
import { getNotifications } from 'modules/notifications';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function MiniDrawer({ location }) {
  // const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const [notiDrawer, setNotiDrawer] = React.useState(false);

  function notiDrawerOpen() {
    setNotiDrawer(true);
  }
  function notiDrawerClose() {
    setNotiDrawer(false);
  }

  return (
    <EchoProvider>
      <div className={classes.root}>
        <TopBar
          notiOpen={notiDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          drawerWidth={drawerWidth}
        />
        <NotificationDrawer open={notiDrawer} onClose={notiDrawerClose} />
        <Sidebar
          location={location}
          open={open}
          handleDrawerClose={handleDrawerClose}
          drawerWidth={drawerWidth}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route path="/profile" component={Profile} />
            {/* <Route path="/billing-details" component={BillingDetails} /> */}
            {/* <Route path="/cashback-claim" component={CashbackClaim} /> */}
            <Route path="/key-analytics" component={KeyAnalytics} />
            <Route path="/menu/edit/:id" component={EditMenu} />
            {/* <Route path="/menu/upload" component={UploadMenu} /> */}
            <Route path="/menu" component={Menu} />
            <Route path="/promote-business" component={PromoteBusiness} />
            <Route path="/referral-income" component={ReferralIncome} />
            <Route path="/review/:id" component={SingleReview} />
            <Route path="/review" component={Review} />
            <Route path="/switch-account" component={SwitchAccount} />
            <Route path="/voucher-income" component={VoucherIncome} />
            <Route path="/voucher" component={VoucherIncome} />
            <Route path="/" component={BusinessSummary} />
          </Switch>
        </main>
      </div>
    </EchoProvider>
  );
}
