import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import debounce from 'lodash.debounce';

// import ColorCard from './business-summary/ColorCard';
import IconCard from './business-summary/IconCard';

import { acceptVoucherRedeem, getVoucherHistory, getVoucherRedeems } from '../modules/voucher';
import { getIncome } from '../modules/income';

// import smallAvatar from '../assets/avatar.png';
import giftIcon from '../assets/gift-icon.png';

const useSyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // padding: theme.spacing(3, 2),
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(4),
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
    '& > $column': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tableBody: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #D8D8D8',
    padding: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  column: {
    // flexGrow: 1,
    width: '100%',
    display: 'flex',
    marginRight: '1rem',
    '& > img': {
      width: '100px',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    fontWeight: 'bold',
  },
  button: {
    alignSelf: 'start',
    marginLeft: 15,
  },
  colorCard: {
    height: '145px',
  },
  loading: {
    display: 'flex',
    height: '30vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function VoucherIncome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useSyles();
  const dispatch = useDispatch();

  const voucherHistory = useSelector(state => state.voucher.history.data);
  const voucherRedeems = useSelector(state => state.voucher.redeems.data);

  const voucherLoading = useSelector(state => state.voucher.history.loading);

  const voucherAcceptLoading = useSelector(state => state.voucher.accept.loading);

  const incomeData = useSelector(state => state.income.data);
  const incomeLoading = useSelector(state => state.income.loading);

  useEffect(() => {
    dispatch(getVoucherRedeems());
    dispatch(getVoucherHistory());
    dispatch(getIncome());
  }, [dispatch]);

  const acceptVoucher = id => {
    dispatch(acceptVoucherRedeem(id));
  };

  const getSearchResult = debounce(function(e) {
    dispatch(getVoucherHistory({ customer: e.target.value }));
  }, 500);

  function handleSearch(e) {
    e.persist();
    getSearchResult(e);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <IconCard
            loading={incomeLoading}
            primaryText="Total Voucher Income"
            secondaryText={incomeData.voucher_income || 0}
            icon={giftIcon}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <ColorCard
            loading={incomeLoading}
            primaryText={incomeData.voucher_income || 0}
            secondaryText="Income From Happihub (Weekly)"
            color="#7266BA"
            className={classes.colorCard}
          />
        </Grid> */}
      </Grid>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="center">
          <Box flexGrow="1">
            <Typography variant="h6" gutterBottom>
              Voucher Summary
            </Typography>
          </Box>
          <Box>
            <TextField
              id="search"
              label="Search Customer"
              type="search"
              variant="outlined"
              onChange={handleSearch}
              className={classes.textField}
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: '#4a4a4a' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div className={classes.column}>Name</div>
            <div className={classes.column}>Amount</div>
            <div className={classes.column}>Type</div>
            <div className={classes.column}>Visit</div>
            {/* <div className={classes.column}>Income</div> */}
            <div className={classes.column}>Status</div>
          </div>
          {voucherLoading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {voucherRedeems.length && !voucherLoading
            ? voucherRedeems.map(item => (
                <div className={classes.tableBody} key={item.id}>
                  <div className={classes.column}>
                    <Avatar
                      alt="Remy Sharp"
                      src={item.customer.profile_picture}
                      className={classes.avatar}
                    />
                    <div className={classes.name}>
                      <Typography variant="body1" gutterBottom>
                        {item.customer.name}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {format(parseISO(item.updated_at), 'do LLL, yyyy')}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.column}>
                    <Typography variant="body1" gutterBottom>
                      ৳{item.voucher.value}
                    </Typography>
                  </div>
                  <div className={classes.column}>
                    <Typography variant="body1" gutterBottom>
                      Regular
                    </Typography>
                  </div>
                  <div className={classes.column}>
                    <Typography variant="body1" gutterBottom>
                      1
                    </Typography>
                  </div>
                  {/* <div className={classes.column}>
                  <Typography variant="body1" gutterBottom>
                    ৳{item.voucher.value}
                  </Typography>
                </div> */}
                  <div className={classes.column}>
                    {item.status === 'REQUESTED' ? (
                      <>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          disabled={voucherAcceptLoading[item.id]}
                          onClick={() => acceptVoucher(item.id)}
                        >
                          Accept
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          disabled={voucherAcceptLoading[item.id]}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <div>{item.status === 'APPLIED' && 'Accepted'}</div>
                    )}
                  </div>
                </div>
              ))
            : !voucherLoading && (
                <Typography align="center" variant="subtitle1" gutterBottom>
                  No Voucher Found
                </Typography>
              )}
        </div>
      </Paper>
    </div>
  );
}
