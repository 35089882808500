import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ContentLoader from 'react-content-loader';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minWidth: 200,
    height: 145,
    justifyContent: 'space-between',
    // boxShadow: '2px 5px 6px rgba(0, 0, 0, 0.2)',
  },
  content: props => ({
    flex: '1',
    backgroundImage: `url(${props.icon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
  }),
  // cover: {
  //   width: 110,
  // },
  // coverRoot: {
  //   backgroundSize: 'contain',
  //   backgroundPosition: 'bottom right',
  // },
  primaryText: {
    // fontWeight: 'bold',
    fontSize: '20px',
  },
  secondaryText: {
    fontWeight: 'bold',
  },
}));

export default function IconCard({ primaryText, secondaryText, icon, loading }) {
  const classes = useStyles({ icon });

  if (loading) {
    return (
      <ContentLoader
        height={160}
        width={400}
        speed={1}
        primaryColor="#ffffff"
        secondaryColor="#c3e5ff"
      >
        <rect x="189" y="24" rx="0" ry="0" width="0" height="0" />
        <rect x="2" y="4" rx="0" ry="0" width="393" height="137" />
      </ContentLoader>
    );
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h6" className={classes.primaryText} gutterBottom>
          {primaryText}
        </Typography>
        <Typography variant="h5" className={classes.secondaryText}>
          ৳{secondaryText}
        </Typography>
      </CardContent>

      {/* <CardMedia
        classes={{ root: classes.coverRoot }}
        className={classes.cover}
        image={icon}
        title={primaryText}
      /> */}
    </Card>
  );
}
