import { createSlice, createSelector } from 'redux-starter-kit';
import Voucher from '../repositories/Voucher';

const voucherSlice = createSlice({
  slice: 'voucher',
  initialState: {
    redeems: {
      loading: false,
      error: '',
      data: [],
    },
    accept: {
      loading: {},
      error: '',
    },
    history: {
      loading: {},
      error: '',
      data: [],
    },
  },
  reducers: {
    getVoucherRedeems: (state, action) => {
      state.redeems.loading = true;
    },
    getVoucherRedeemsSuccess: (state, action) => {
      state.redeems.loading = false;
      state.redeems.error = false;
      state.redeems.data = action.payload.data;
    },
    getVoucherRedeemsFailed: (state, action) => {
      state.redeems.loading = false;
      state.redeems.error = action.payload;
    },

    getVoucherHistory: (state, action) => {
      state.history.loading = true;
    },
    getVoucherHistorySuccess: (state, action) => {
      state.history.loading = false;
      state.history.error = false;
      state.history.data = action.payload.data;
    },
    getVoucherHistoryFailed: (state, action) => {
      state.history.loading = false;
      state.history.error = action.payload;
    },

    acceptVoucherRedeem: (state, action) => {
      state.accept.loading[action.payload] = true;
      // state.redeems.data.forEach(item => {
      //   if (item.id === action.payload.data.id) {
      //     item.loading = true;
      //   }
      // });
    },
    acceptVoucherRedeemSuccess: (state, action) => {
      state.accept.loading[action.payload.data.id] = false;
      // state.accept.error = false;
      state.redeems.data.forEach(item => {
        if (item.id === action.payload.data.id) {
          item.status = action.payload.data.status;
        }
      });
    },
    acceptVoucherRedeemFailed: (state, action) => {
      // state.accept.loading[action.payload.data.id] = false;
      // state.redeems.data.forEach(item => {
      //   if (item.id === action.payload.data.id) {
      //     item.loading = false;
      //     item.error = action.payload;
      //   }
      // });
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = voucherSlice;

export const selectors = {
  getVoucher: createSelector(
    state => state.voucher,
    data => data,
  ),
};

// export const graphDataSelector = createSelector(
//   ['voucher.graph.data'],
//   data => {
//     return Object.keys(data).map(key => ({
//       name: key,
//       value: data[key],
//     }));
//   },
// );

export const getVoucherRedeems = (query = { page: 1, limit: 10 }) => async (dispatch, getState) => {
  dispatch(actions.getVoucherRedeems());
  try {
    const response = await Voucher.getVoucherRedeems(query);
    if (response.success) {
      dispatch(actions.getVoucherRedeemsSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getVoucherRedeemsFailed(error));
  }
};

export const getVoucherHistory = (query = {}) => async (dispatch, getState) => {
  dispatch(actions.getVoucherHistory());
  try {
    const response = await Voucher.getVoucherHistory(query);
    if (response.success) {
      dispatch(actions.getVoucherHistorySuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getVoucherHistoryFailed(error));
  }
};

export const acceptVoucherRedeem = id => async (dispatch, getState) => {
  dispatch(actions.acceptVoucherRedeem(id));
  try {
    const response = await Voucher.acceptVoucherRedeem(id);
    if (response.success) {
      dispatch(actions.acceptVoucherRedeemSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.acceptVoucherRedeemFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
