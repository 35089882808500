import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

// import InfoCard from './profile/InfoCard';
import About from './profile/About';
import BusinessRating from './profile/BusinessRating';
import BusinessInfo from './profile/BusinessInfo';
import ProfileCover from './profile/ProfileCover';
import Specialities from './profile/Specialities';
import BusinessHours from './profile/BusinessHours';
import MenuService from './profile/MenuService';
import Tags from './profile/Tags';

import { useDispatch, useSelector } from 'react-redux';
import {
  getProfile,
  editProfile,
  addSpeciality,
  deleteSpeciality,
  addTags,
  deleteTags,
  selectors as profileSelector,
} from '../modules/profile';
import { getMenuList, selectors as menuSelector } from '../modules/menu';

// import coverPic from '../assets/cover-pic.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cover: props => ({
    backgroundImage: `url(${props.cover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    height: '425px',
    padding: theme.spacing(8),
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      height: 'auto',
      flexDirection: 'column',
    },
  }),
  infoSection: {
    position: 'relative',
    top: '-66px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  loading: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const { data, loading, coverUploading, submitting } = useSelector(profileSelector.getProfile);
  const menus = useSelector(menuSelector.getMenu);
  const classes = useStyles({ cover: data.cover_picture });

  const handleEditProfile = values => {
    dispatch(editProfile(values));
  };

  const handleAddSpeciality = values => {
    // console.log(values);
    dispatch(addSpeciality(values));
  };

  const handleDeleteSpeciality = values => {
    dispatch(deleteSpeciality(values));
  };

  const handleAddTags = values => {
    dispatch(addTags(values));
  };

  const handleDeleteTags = values => {
    dispatch(deleteTags(values));
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getMenuList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {coverUploading && <LinearProgress />}
      <div className={classes.cover}>
        <ProfileCover
          likeCount={data.favorite_count}
          profile={data.profile_picture}
          name={data.name}
        />
      </div>
      <div className={classes.infoSection}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <About
              onSubmit={handleEditProfile}
              submitting={submitting}
              description={data.merchant_info.description}
            />
            <BusinessRating data={data.rating} />
            <Specialities
              onSubmit={handleAddSpeciality}
              onDelete={handleDeleteSpeciality}
              submitting={submitting}
              data={data.specialities}
            />
            <Tags
              data={data.tags}
              onSubmit={handleAddTags}
              onDelete={handleDeleteTags}
              submitting={submitting}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <BusinessInfo onSubmit={handleEditProfile} submitting={submitting} data={data} />
            <BusinessHours />
            <MenuService data={menus.data} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
