import React from 'react';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.2)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  fab: {
    margin: theme.spacing(1),
    position: 'absolute',
    top: 16,
    right: 16,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ImageModal({ handleClose, open, src }) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      fullScreen={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="full-screen-image"
    >
      {/* <Button
        className={classes.closeBtn}
        onClick={handleClose}
        color="secondary"
        variant="contained"
        startIcon={}
      >
        Close
      </Button> */}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="close"
        className={classes.fab}
        onClick={handleClose}
      >
        <CloseIcon />
        Close
      </Fab>
      <img src={src} alt="Bill" style={{ objectFit: 'contain', height: '100%' }} />
    </Dialog>
  );
}
