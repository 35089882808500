import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getMenuList, deleteMenu, selectors } from 'modules/menu';
import ImageModal from 'components/ImageModal';
import UploadMenu from 'pages/menu/UploadMenu';

// import menuImg from '../assets/menu.png';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  card: {
    width: '100%',
  },
  media: {
    height: 200,
  },
  titleBar: {
    marginBottom: theme.spacing(2),
  },
  description: {
    padding: 8,
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    fontWeight: 'bold',
    color: '#FFF',
  },
  btn: {
    fontWeight: 'bold',
  },
}));

export default function Menu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const menus = useSelector(selectors.getMenu);

  useEffect(() => {
    dispatch(getMenuList());
  }, [dispatch]);

  const handleDeleteMenu = id => {
    dispatch(deleteMenu(id));
  };

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  function handleClickOpen(e, url) {
    e.preventDefault();
    setImageUrl(url);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Paper className={classes.paper}>
      <ImageModal src={imageUrl} open={open} handleClose={handleClose} />
      <UploadMenu />

      <Grid container spacing={2}>
        {menus.data.length ? (
          menus.data.map(item => (
            <Grid key={item.id} item xs={12} md={3}>
              <Card className={classes.card}>
                <CardActionArea onClick={e => handleClickOpen(e, item.image)}>
                  <CardMedia
                    className={classes.media}
                    image={item.image}
                    title="Contemplative Reptile"
                  />
                </CardActionArea>
                <div className={classes.description}>{`${item.name} - ${item.price} Tk`}</div>
                <CardActions>
                  <Button
                    component={Link}
                    to={`/menu/edit/${item.id}`}
                    fullWidth
                    size="small"
                    color="primary"
                    className={classes.btn}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={menus.deleting}
                    onClick={() => handleDeleteMenu(item.id)}
                    fullWidth
                    size="small"
                    color="secondary"
                    className={classes.btn}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              No Menu Items Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
