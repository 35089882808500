import { createSlice, createSelector } from 'redux-starter-kit';
import Billing from '../repositories/Billing';

const billingSlice = createSlice({
  slice: 'billing',
  initialState: {
    loading: false,
    error: '',
    summery: {},
    history: {},
  },
  reducers: {
    getBillingSummary: (state, action) => {
      state.loading = true;
    },
    getBillingSummarySuccess: (state, action) => {
      state.loading = false;
      state.summery = action.payload.data;
    },
    getBillingSummaryFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBillingHistory: (state, action) => {
      state.loading = true;
    },
    getBillingHistorySuccess: (state, action) => {
      state.loading = false;
      state.history = action.payload.data;
    },
    getBillingHistoryFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = billingSlice;

export const selectors = {
  getBilling: createSelector(
    state => state.billing,
    data => data,
  ),
};

export const getBillingSummary = () => async (dispatch, getState) => {
  dispatch(actions.getBillingSummary());
  try {
    const response = await Billing.getBillingSummary();
    if (response.success) {
      dispatch(actions.getBillingSummarySuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getBillingSummaryFailed(error));
    return {
      error: true,
    };
  }
};

export const getBillingHistory = query => async (dispatch, getState) => {
  dispatch(actions.getBillingHistory());
  try {
    const response = await Billing.getBillingHistory(query);
    if (response.success) {
      dispatch(actions.getBillingHistorySuccess(response));
      return response;
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getBillingHistoryFailed(error));
    return {
      error: true,
    };
  }
};
// Export the reducer, either as a default or named export
export default reducer;
