import React from 'react';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoCard from './InfoCard';
import Box from '@material-ui/core/Box';

// const useStyle = makeStyles(theme => ({}));

export default function BusinessHours() {
  // const classes = useStyle();

  return (
    <InfoCard title="Business hours">
      <Box
        color="primary.main"
        fontWeight="fontWeightBold"
        display="flex"
        justifyContent="start"
        lineHeight={3}
      >
        <Box width={['40%', '30%', '30%']}>Day</Box>
        <Box>Opening/Closing Time</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Saturday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Sunday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Monday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Tuesday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Wednesday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Thursday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
      <Box fontWeight="fontWeightBold" display="flex" justifyContent="start" lineHeight={2}>
        <Box width={['40%', '30%', '30%']}>Friday</Box>
        <Box fontWeight="fontWeightRegular">11.00 a.m. - 11.00 p.m.</Box>
      </Box>
    </InfoCard>
  );
}
