import SuperFetch from '../api/SuperFetch';

class Review {
  getReviews() {
    return SuperFetch('merchant/reviews');
  }

  getSingleReview(id) {
    return SuperFetch(`merchant/reviews/${id}`);
  }

  likeReview(id) {
    return SuperFetch(`merchant/reviews/${id}/like`, { method: 'POST' });
  }

  postComment(values) {
    return SuperFetch(`merchant/comments`, { method: 'POST', body: JSON.stringify(values) });
  }

  dislikeReview(id) {
    return SuperFetch(`merchant/reviews/${id}/dislike`, { method: 'POST' });
  }
}

export default new Review();
