import React, { useState } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

import InfoCard from './InfoCard';
import ImageModal from '../../components/ImageModal';

// import menu from '../../assets/menu.png';

const useStyle = makeStyles(theme => ({
  image: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridList: {
    marginBottom: theme.spacing(2),
  },
  linkButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
}));

export default function MenuService({ data }) {
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  function handleClickOpen(url) {
    setImageUrl(url);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ImageModal src={imageUrl} open={open} handleClose={handleClose} />
      <InfoCard title="Menu/Products Photo">
        {data.length ? (
          <GridList cellHeight={100} className={classes.gridList} cols={3}>
            {data.map(item => (
              <GridListTile key={item.id} cols={1}>
                <img
                  src={item.image}
                  alt="menu"
                  className={classes.image}
                  onClick={() => handleClickOpen(item.image)}
                />
              </GridListTile>
            ))}
          </GridList>
        ) : (
          <Typography align="center" variant="subtitle1">
            No Menu Items Found
          </Typography>
        )}
        <Button className={classes.linkButton} color="primary" component={Link} to="/menu">
          Goto Gallery{' '}
          <span role="img" aria-label="Point Right">
            👉
          </span>
        </Button>
      </InfoCard>
    </>
  );
}
