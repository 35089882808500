import SuperFetch from '../api/SuperFetch';

class Dashboard {
  getIncome() {
    return SuperFetch('merchant/incomes');
  }
  getCustomers() {
    return SuperFetch('merchant/latest-customers');
  }
  getPageStats() {
    return SuperFetch('merchant/page-stats');
  }
}

export default new Dashboard();
