import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoCard from './InfoCard';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import useForm from 'react-hook-form';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AsyncSelect from 'react-select/async-creatable';

import Profile from 'repositories/Profile';

const useStyle = makeStyles(theme => ({
  list: {
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
    '& > li': {
      width: '30%',
      display: 'flex',
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    color: '#7266BA',
  },
  drawer: {
    maxWidth: 400,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  headerTxt: {
    marginBottom: theme.spacing(2),
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}));

const promiseOptions = async input => {
  const res = await Profile.getTags(input);
  // console.log(res);
  return res.data.length && res.data.map(item => ({ label: item.name, value: item.name }));
};

export default function Tags({ data, submitting, onDelete, onSubmit }) {
  const classes = useStyle();
  const { register, handleSubmit, setValue } = useForm();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    register({ name: 'name', required: true });
  }, [register]);

  const [tag, setTag] = useState('');

  const handleSelect = selected => {
    if (selected) {
      setTag(selected);
      setValue('name', selected.value);
    } else {
      setTag('');
      setValue('name', '');
    }
  };

  return (
    <>
      <InfoCard
        title="Tags"
        rightItem={
          <Button color="primary" onClick={handleClickOpen}>
            Edit
          </Button>
        }
      >
        {data.length ? (
          <ul className={classes.list}>
            {data.map(tag => (
              <li>
                <LabelIcon className={classes.icon} />
                <Typography variant="body1">{tag.name}</Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Typography align="center" variant="body1">
            No Tags Found. Add few now?
          </Typography>
        )}
      </InfoCard>
      <Drawer anchor="right" open={open} onClose={handleClose} classes={{ paper: classes.drawer }}>
        <Typography variant="body1" align="center" gutterBottom className={classes.headerTxt}>
          Add some Tags for your business! e.g: Burger, Pizza, Fastfood, Chinese etc.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable
            placeholder="Add Speciality"
            value={tag}
            onChange={handleSelect}
            loadOptions={promiseOptions}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={submitting || !tag}
            type="submit"
            color="primary"
            className={classes.submitBtn}
          >
            Add
          </Button>
        </form>
        <List>
          {data.map((item, i) => (
            <ListItem>
              <ListItemIcon>
                <LabelIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => onDelete(item.name)} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
