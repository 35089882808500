import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import likebadge from '../../assets/like-badge.png';

const useStyle = makeStyles(theme => ({
  card: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    justifyContent: 'space-between',
    // boxShadow: '2px 5px 6px rgba(0, 0, 0, 0.2)',
    background: 'linear-gradient(270deg, rgba(77,66,155,1) 21%, rgba(149,85,203,1) 131%)',
    color: '#fff',
    height: '280px',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  primaryText: {
    fontWeight: 'bold',
  },
}));

export default function BadgeCard({ className, pageLikes = 0 }) {
  const classes = useStyle();

  return (
    <Card className={clsx(classes.card, className)}>
      <CardContent className={classes.content}>
        <img style={{ margin: '1rem' }} src={likebadge} alt="Like Badge" />
        <Typography variant="body1" gutterBottom align="center">
          Total Page Likes
        </Typography>
        <Typography variant="h3" align="center" className={classes.primaryText}>
          {pageLikes}
        </Typography>
      </CardContent>
    </Card>
  );
}
