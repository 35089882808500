import SuperFetch from '../api/auth';

class Auth {
  login(values) {
    return SuperFetch('merchant/login', {
      method: 'POST',
      body: JSON.stringify(values),
    });
  }

  checkLogin(token) {
    SuperFetch('logincheck');
  }
}

export default new Auth();
