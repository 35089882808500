import React from 'react';

const SvgReview = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="fill"
      d="M388.91 501.72c-6.31 0-12.64-1.54-18.48-4.66l-108.66-58.07a9.546 9.546 0 0 0-9.02 0l-108.67 58.07c-13.27 7.09-29.11 6.03-41.33-2.76-12.45-8.95-18.59-23.97-16.02-39.18l20.75-122.99c.57-3.4-.52-6.85-2.92-9.23l-87.91-87.1c-10.84-10.74-14.6-26.45-9.82-41.02 4.72-14.4 16.89-24.7 31.75-26.9l121.49-17.94c3.19-.47 5.97-2.54 7.43-5.54l54.34-111.9c6.68-13.76 20.26-22.31 35.43-22.31 15.17 0 28.75 8.55 35.43 22.31l54.33 111.9c1.46 3 4.23 5.07 7.43 5.54l121.49 17.94c14.86 2.2 27.02 12.5 31.75 26.9 4.78 14.56 1.01 30.28-9.82 41.01l-87.91 87.1c-2.4 2.38-3.49 5.83-2.92 9.22L427.8 455.1c2.57 15.21-3.57 30.22-16.02 39.18-6.87 4.94-14.85 7.44-22.87 7.44zm-131.66-93.45c6.34 0 12.67 1.54 18.45 4.63l108.66 58.07c4.75 2.53 8.66.37 10.12-.68 1.22-.88 5.14-4.22 4.12-10.24l-20.75-122.99c-2.18-12.87 2.04-26.02 11.27-35.16l87.91-87.1c4.25-4.21 3.02-9.31 2.54-10.78-.59-1.79-2.54-6.05-7.96-6.85l-121.49-17.94c-12.88-1.9-23.98-10.09-29.72-21.89l-54.33-111.9c-2.47-5.09-6.99-5.64-8.81-5.64-1.83 0-6.34.55-8.81 5.64l-54.33 111.9c-5.73 11.8-16.84 19.99-29.72 21.89L42.89 197.16c-5.42.8-7.37 5.06-7.96 6.85-.48 1.46-1.71 6.56 2.54 10.78l87.91 87.1c9.23 9.14 13.44 22.29 11.27 35.16L115.9 460.04c-1.02 6.02 2.9 9.36 4.12 10.24 1.46 1.05 5.37 3.22 10.12.68L238.8 412.9a39.049 39.049 0 0 1 18.45-4.63zm0 0"
    />
  </svg>
);

export default SvgReview;
