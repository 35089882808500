import SuperFetch from '../api/SuperFetch';
import querystring from 'query-string';

class Billing {
  getBillingSummary() {
    return SuperFetch('merchant/payments/summery');
  }
  getBillingHistory(query = { page: 1, limit: 10 }) {
    return SuperFetch(`merchant/payments?${querystring.stringify(query)}`);
  }
}

export default new Billing();
