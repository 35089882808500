import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    width: '100%',
    height: '280px',
  },
  totalSales: {
    fontWeight: 'bold',
  },
}));

const constData = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 3000,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Jul',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Aug',
    uv: 3490,
    pv: 525,
    amt: 3621,
  },
  {
    name: 'Sep',
    uv: 2241,
    pv: 362,
    amt: 136,
  },
  {
    name: 'Oct',
    uv: 2412,
    pv: 5351,
    amt: 242,
  },
  {
    name: 'Nov',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Dec',
    uv: 2151,
    pv: 242,
    amt: 151,
  },
];

export default function TotalSale({ data, totalSales }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Total Sales
      </Typography>
      <Typography className={classes.totalSales} variant="h5" gutterBottom>
        ৳{totalSales}
      </Typography>
      <ResponsiveContainer>
        <BarChart
          data={constData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 60,
          }}
        >
          {/* <CartesianGrid strokeDasharray="0" /> */}
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip />
          <Bar dataKey="pv" stackId="a" fill="#8884d8" />
          <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}
