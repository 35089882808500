import React, { useCallback, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { editMenu, getSingleMenu, selectors } from '../modules/menu';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  card: {
    width: '100%',
  },
  media: {
    height: 200,
  },
  titleBar: {
    marginBottom: theme.spacing(2),
  },
  dropzone: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
  },
}));

export default function EditMenu({ match }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const [imageUpload, setImageUpload] = useState(null);
  const dispatch = useDispatch();
  const single = useSelector(selectors.getSingleMenu);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: single.name,
      price: single.price,
      description: single.description,
    },
  });

  useEffect(() => {
    dispatch(getSingleMenu(match.params.id));
    // console.log(props);
  }, [dispatch, match.params.id]);

  const [image, setImage] = useState(single.image);

  useEffect(() => {
    setImage(single.image);
    setValue('name', single.name);
    setValue('price', single.price);
    setValue('description', single.description);
  }, [setValue, single.description, single.image, single.name, single.price]);

  const onSubmit = values => {
    console.log(values);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', values.price);
    formData.append('description', values.description);
    if (imageUpload) {
      formData.append('image', imageUpload);
    }
    dispatch(editMenu(single.id, formData));
  };

  const onDrop = useCallback(acceptedFiles => {
    setImageUpload(acceptedFiles[0]);
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const imageData = reader.result;
      setImage(imageData);
      // console.log(imageData);
    };

    acceptedFiles.forEach(file => reader.readAsDataURL(file));
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
  });
  const { ref, ...rootProps } = getRootProps();
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.titleBar}
      >
        <Grid item>
          <Typography gutterBottom variant="h5">
            Edit Menu/Products/Services
          </Typography>
        </Grid>
      </Grid>
      <RootRef rootRef={ref}>
        <Paper {...rootProps} className={classes.dropzone}>
          <input {...getInputProps()} />
          <Button size="small" color="primary" variant="outlined">
            Change Photo
          </Button>
        </Paper>
      </RootRef>

      {image && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img className={classes.image} src={image} alt="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                id="name"
                label="Name"
                name="name"
                fullWidth
                inputRef={register}
                className={classes.textField}
                margin="normal"
              />
              <TextField
                id="price"
                label="Price"
                name="price"
                fullWidth
                inputRef={register}
                className={classes.textField}
                margin="normal"
              />
              <TextField
                id="description"
                name="description"
                label="Description"
                multiline
                fullWidth
                rows="4"
                rowsMax="10"
                inputRef={register}
                className={classes.textField}
                margin="normal"
              />
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Upload
              </Button>
            </form>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
