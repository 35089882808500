import React from 'react';

const SvgBusinessSummary = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="fill">
      <path d="M71.55 261.25H11.09c-4.14 0-7.5 3.35-7.5 7.49v217.12c0 4.14 3.36 7.5 7.5 7.5h60.46c4.14 0 7.5-3.36 7.5-7.5v-68.52c0-4.14-3.36-7.49-7.5-7.49-4.14 0-7.5 3.35-7.5 7.49v61.02H18.59V276.25h45.47v116.11c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.35 7.5-7.5V268.75c-.01-4.14-3.37-7.5-7.51-7.5zm0 0M178.89 331.02h-60.46c-4.14 0-7.5 3.36-7.5 7.5v147.35c0 4.14 3.36 7.5 7.5 7.5h60.46c4.14 0 7.5-3.36 7.5-7.5V338.52c0-4.14-3.36-7.5-7.5-7.5zm-7.49 147.35h-45.47V346.02h45.47v132.35zm0 0M286.23 214.74h-60.46c-4.14 0-7.5 3.35-7.5 7.5v263.63c0 4.14 3.36 7.5 7.5 7.5h60.46c4.14 0 7.5-3.36 7.5-7.5V222.23c0-4.14-3.36-7.49-7.5-7.49zm-7.49 263.63h-45.47V229.73h45.47v248.64zm0 0M393.57 249.63h-60.46c-4.14 0-7.5 3.35-7.5 7.49v228.75c0 4.14 3.36 7.5 7.5 7.5h60.46c4.14 0 7.5-3.36 7.5-7.5V257.12c0-4.14-3.36-7.49-7.5-7.49zm-7.5 228.74H340.6V264.62h45.47v213.75zm0 0M500.91 144.97h-60.46c-4.14 0-7.5 3.36-7.5 7.5v98.95c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5v-91.45h45.47v318.41h-45.47V276.41c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5v209.46c0 4.14 3.36 7.5 7.5 7.5h60.46c4.14 0 7.5-3.36 7.5-7.5v-333.4c0-4.14-3.36-7.5-7.5-7.5zm0 0" />
      <path d="M449.44 86.81a37.496 37.496 0 0 0 21.24 6.56c20.81 0 37.73-16.93 37.73-37.73 0-20.81-16.92-37.73-37.73-37.73-20.8 0-37.73 16.92-37.73 37.73 0 7.52 2.22 14.53 6.03 20.42l-54.41 53.06a37.464 37.464 0 0 0-21.23-6.56c-14.06 0-26.34 7.74-32.83 19.17l-37.03-12.03c.16-1.41.25-2.85.25-4.3 0-20.8-16.93-37.73-37.73-37.73-20.81 0-37.73 16.93-37.73 37.73 0 8.23 2.66 15.86 7.16 22.07l-57.2 61.97a37.575 37.575 0 0 0-19.57-5.48c-10.52 0-20.05 4.33-26.9 11.3L76.4 185.79c1.7-4.29 2.65-8.97 2.65-13.86 0-20.8-16.93-37.73-37.73-37.73-20.8 0-37.72 16.92-37.72 37.73 0 20.8 16.92 37.73 37.73 37.73 10.52 0 20.05-4.34 26.9-11.31l45.36 29.48c-1.7 4.29-2.65 8.97-2.65 13.87 0 20.8 16.93 37.73 37.73 37.73 20.8 0 37.73-16.92 37.73-37.73 0-8.23-2.66-15.86-7.16-22.07l57.2-61.97a37.482 37.482 0 0 0 19.57 5.48c14.06 0 26.34-7.74 32.83-19.17L325.86 156a38.19 38.19 0 0 0-.25 4.3c0 20.81 16.92 37.73 37.73 37.73 20.81 0 37.73-16.92 37.73-37.73 0-7.53-2.22-14.54-6.03-20.42l54.4-53.07zM41.32 194.66c-12.54 0-22.74-10.2-22.74-22.74s10.2-22.73 22.74-22.73 22.73 10.2 22.73 22.73c.01 12.54-10.19 22.74-22.73 22.74zm107.34 69.77c-12.54 0-22.74-10.2-22.74-22.73 0-12.54 10.2-22.74 22.74-22.74s22.73 10.2 22.73 22.74c.01 12.53-10.19 22.73-22.73 22.73zM256 148.15c-12.54 0-22.74-10.2-22.74-22.74s10.2-22.73 22.74-22.73 22.74 10.2 22.74 22.73c0 12.54-10.2 22.74-22.74 22.74zM470.68 32.91c12.54 0 22.74 10.2 22.74 22.74s-10.2 22.73-22.74 22.73-22.73-10.2-22.73-22.73c-.01-12.54 10.19-22.74 22.73-22.74zM363.34 183.03c-12.54 0-22.73-10.19-22.73-22.73 0-12.54 10.2-22.73 22.73-22.73 12.54 0 22.74 10.2 22.74 22.73-.01 12.54-10.2 22.73-22.74 22.73zm0 0" />
    </g>
  </svg>
);

export default SvgBusinessSummary;
