import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ContentLoader from 'react-content-loader';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    justifyContent: 'space-between',
    // boxShadow: '2px 5px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: props => (props.color ? props.color : '#fff'),
    color: '#fff',
    height: '145px',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  primaryText: {
    fontWeight: 'bold',
  },
}));

export default function ColorCard({ primaryText, secondaryText, color, loading, className }) {
  const classes = useStyles({ color });

  if (loading) {
    return (
      <ContentLoader
        height={160}
        width={400}
        speed={1}
        primaryColor="#ffffff"
        secondaryColor="#c3e5ff"
      >
        <rect x="189" y="24" rx="0" ry="0" width="0" height="0" />
        <rect x="2" y="4" rx="0" ry="0" width="393" height="137" />
      </ContentLoader>
    );
  }

  return (
    <Card className={clsx(classes.card, className)}>
      <CardContent className={classes.content}>
        <Typography variant="body1" gutterBottom align="center">
          {secondaryText}
        </Typography>
        <Typography variant="h3" align="center" className={classes.primaryText}>
          ৳{primaryText}
        </Typography>
      </CardContent>
    </Card>
  );
}
