import { configureStore } from 'redux-starter-kit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web\
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createFilter from 'redux-persist-transform-filter';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

export default function configureAppStore(preloadedState = {}) {
  const saveSubsetFilter = createFilter('auth', ['data', 'accounts', 'loginStatus']);
  const persistConfig = {
    key: 'happihub_merchant_auth',
    storage,
    whitelist: ['auth'],
    stateReconciler: autoMergeLevel2,
    transforms: [saveSubsetFilter],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    preloadedState,
    enhancers: [],
  });

  let persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(persistedReducer));
  }

  return { store, persistor };
}
