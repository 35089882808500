import React from 'react';

const SvgMenu = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="fill">
      <path d="M3.38 456.65c0 29.47 23.99 53.35 53.35 53.35h399.3c29.47 0 53.35-23.99 53.35-53.35V57.35C509.38 27.88 485.39 4 456.02 4H56.73C27.26 4 3.38 27.99 3.38 57.35v399.3zm452.64 28.02H56.73c-15.41 0-28.02-12.62-28.02-28.02v-66.68l95.95-95.95L206.65 376a12.614 12.614 0 0 0 17.89 0L372.6 227.94 484.05 339.4v117.24c0 15.42-12.62 28.03-28.03 28.03zM56.73 29.33h399.3c15.41 0 28.02 12.61 28.02 28.02v246.18L381.48 201.06a12.614 12.614 0 0 0-17.89 0L215.54 349.12l-81.99-81.99a12.614 12.614 0 0 0-17.89 0l-86.95 86.95V57.35c0-15.41 12.61-28.02 28.02-28.02zm0 0" />
      <path d="M160.22 206.75c35.57 0 64.41-28.95 64.41-64.41 0-35.46-28.95-64.41-64.41-64.41-35.46 0-64.41 28.95-64.41 64.41 0 35.46 28.85 64.41 64.41 64.41zm0-103.49c21.61 0 39.09 17.57 39.09 39.08 0 21.51-17.58 39.08-39.09 39.08-21.51 0-39.08-17.58-39.08-39.08 0-21.51 17.48-39.08 39.08-39.08zm0 0" />
    </g>
  </svg>
);

export default SvgMenu;
