import { createSlice, createSelector } from 'redux-starter-kit';
import Dashboard from '../repositories/Dashboard';

const customerSlice = createSlice({
  slice: 'customers',
  initialState: {
    loading: false,
    error: '',
    data: {
      latest_customers: [],
    },
  },
  reducers: {
    getCustomers: (state, action) => {
      state.loading = true;
    },
    getCustomersSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getCustomersFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = customerSlice;

export const selectors = {
  getCustomers: createSelector(
    state => state.customers,
    data => data,
  ),
};

export const getCustomers = () => async (dispatch, getState) => {
  dispatch(actions.getCustomers());
  try {
    const response = await Dashboard.getCustomers();
    if (response.success) {
      dispatch(actions.getCustomersSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getCustomersFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
