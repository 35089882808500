import React from 'react';

const SvgVoucherIncome = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="fill">
      <path d="M498.88 112.7H151.31l-21.07 21.06-21.06-21.06H14.39c-5.82 0-10.54 4.71-10.54 10.53v252.78c0 5.82 4.72 10.52 10.54 10.52h94.79l21.06-21.05 21.07 21.05h347.57c5.82 0 10.52-4.71 10.52-10.52V123.23c.01-5.82-4.7-10.53-10.52-10.53zm-10.53 252.79H160.03l-14.89-14.9-14.9-14.91-14.89 14.91-14.89 14.9H24.92V133.76h75.54l14.89 14.89 14.89 14.89 14.9-14.89 14.89-14.89h328.32v231.73z" />
      <path d="M277.7 249.62c23.26 0 42.12-18.86 42.12-42.13 0-23.26-18.86-42.13-42.12-42.13-23.27 0-42.13 18.87-42.13 42.13 0 23.27 18.86 42.13 42.13 42.13zm0-63.19c11.62 0 21.07 9.45 21.07 21.06s-9.45 21.06-21.07 21.06c-11.62 0-21.07-9.45-21.07-21.06s9.45-21.06 21.07-21.06zM361.96 249.62c-23.27 0-42.14 18.86-42.14 42.12s18.87 42.15 42.14 42.15c23.26 0 42.13-18.89 42.13-42.15 0-23.26-18.87-42.12-42.13-42.12zm0 63.2c-11.63 0-21.06-9.44-21.06-21.07 0-11.61 9.44-21.05 21.06-21.05s21.05 9.44 21.05 21.05c.01 11.63-9.43 21.07-21.05 21.07zM246.1 333.89c2.9 0 5.54-1.19 7.45-3.09L401 183.34c1.92-1.9 3.09-4.54 3.09-7.44 0-5.81-4.72-10.53-10.53-10.53-2.9 0-5.55 1.18-7.45 3.08L238.66 315.9c-1.9 1.91-3.09 4.53-3.09 7.43 0 5.84 4.72 10.56 10.53 10.56zM130.24 175.89c-5.8 0-10.52 4.71-10.52 10.53s4.72 10.53 10.52 10.53c5.83 0 10.54-4.71 10.54-10.53s-4.71-10.53-10.54-10.53zM130.24 218.02c-5.8 0-10.52 4.71-10.52 10.53 0 5.81 4.72 10.53 10.52 10.53 5.83 0 10.54-4.72 10.54-10.53s-4.71-10.53-10.54-10.53zM130.24 260.15c-5.8 0-10.52 4.71-10.52 10.54 0 5.8 4.72 10.52 10.52 10.52 5.83 0 10.54-4.72 10.54-10.52 0-5.82-4.71-10.54-10.54-10.54zM130.24 302.29c-5.8 0-10.52 4.71-10.52 10.52 0 5.82 4.72 10.52 10.52 10.52 5.83 0 10.54-4.71 10.54-10.52s-4.71-10.52-10.54-10.52z" />
    </g>
  </svg>
);

export default SvgVoucherIncome;
