import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import routes from '../lib/routes';

import Logo from '../assets/logo.png';
import DrawerClose from '../icons/DrawerClose';

import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';

const useStyles = makeStyles(theme => ({
  toolbar: {
    background: '#002447',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuLink: {
    color: '#038FDD',
    fontWeight: 'bold',
  },
  activeLink: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  logo: {
    display: 'block',
    width: '140px',
    marginLeft: '15px',
  },
}));

function SidebarItems({ handleDrawerClose, location }) {
  const classes = useStyles();

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const closeDrawer = () => {
    if (!matches) {
      handleDrawerClose();
    }
  };

  const getRouteActive = path => {
    if (path === '/') {
      return path.includes(location.pathname);
    } else {
      return location.pathname.includes(path);
    }
  };
  return (
    <>
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <DrawerClose width={25} height={25} fill="#fff" />
        </IconButton>
        <img src={Logo} alt="Logo" className={classes.logo} />
      </div>
      <Divider />
      <List>
        {routes.map(({ path, Icon, name }, index) => (
          <ListItem component={Link} to={path} key={path} onClick={closeDrawer}>
            <ListItemIcon>
              <Icon width={25} height={25} fill={getRouteActive(path) ? '#FFFFFF' : '#038FDD'} />
            </ListItemIcon>
            <ListItemText
              primary={name}
              classes={{
                primary: getRouteActive(path) ? classes.activeLink : classes.menuLink,
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default function Sidebar({ open, handleDrawerClose, location }) {
  return (
    <>
      <Hidden only={['xl', 'lg', 'md']}>
        <MobileDrawer handleDrawerClose={handleDrawerClose} open={open}>
          <SidebarItems location={location} handleDrawerClose={handleDrawerClose} />
        </MobileDrawer>
      </Hidden>
      <Hidden only={['sm', 'xs']}>
        <DesktopDrawer handleDrawerClose={handleDrawerClose} open={open}>
          <SidebarItems location={location} handleDrawerClose={handleDrawerClose} />
        </DesktopDrawer>
      </Hidden>
    </>
  );
}
