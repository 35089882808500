import SuperFetch from '../api/SuperFetch';
import querystring from 'query-string';

class Cashback {
  getAll(query, limit = 5) {
    return SuperFetch(`merchant/billclaim/summery?${querystring.stringify(query)}&limit=${limit}`);
  }
  getGraphHistory(days) {
    return SuperFetch(`merchant/billclaim/history?days=${days}`);
  }
  getStatistics() {
    return SuperFetch(`merchant/billclaim/statistics`);
  }
}

export default new Cashback();
