// export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
// export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
// export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// const defaultState = {
//   notifications: [],
// };

// export default (state = defaultState, action) => {
//   switch (action.type) {
//     case ENQUEUE_SNACKBAR:
//       return {
//         ...state,
//         notifications: [
//           ...state.notifications,
//           {
//             key: action.key,
//             ...action.notification,
//           },
//         ],
//       };

//     case CLOSE_SNACKBAR:
//       return {
//         ...state,
//         notifications: state.notifications.map(notification =>
//           action.dismissAll || notification.key === action.key
//             ? { ...notification, dismissed: true }
//             : { ...notification },
//         ),
//       };

//     case REMOVE_SNACKBAR:
//       return {
//         ...state,
//         notifications: state.notifications.filter(notification => notification.key !== action.key),
//       };

//     default:
//       return state;
//   }
// };

// export const enqueueSnackbar = notification => {
//   const key = notification.options && notification.options.key;

//   return {
//     type: ENQUEUE_SNACKBAR,
//     notification: {
//       ...notification,
//       key: key || new Date().getTime() + Math.random(),
//     },
//   };
// };

// export const closeSnackbar = key => ({
//   type: CLOSE_SNACKBAR,
//   dismissAll: !key, // dismiss all if no key has been defined
//   key,
// });

// export const removeSnackbar = key => ({
//   type: REMOVE_SNACKBAR,
//   key,
// });

import { createSlice, createSelector } from 'redux-starter-kit';
import Notification from '../repositories/Notification';

const pageSlice = createSlice({
  slice: 'page',
  initialState: {
    loading: false,
    error: '',
    data: [],
    unread: 0,
    notifications: [],
  },
  reducers: {
    enqueueSnackbar: (state, action) => {
      const key = action.payload.options && action.payload.options.key;
      state.notifications.push({
        ...action.payload,
        key: key || new Date().getTime() + Math.random(),
      });
    },
    closeSnackbar: (state, action) => {
      state.notifications = state.notifications.map(notification =>
        !action.payload || notification.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification },
      );
    },
    removeSnackbar: (state, action) => {
      state.notifications = state.notifications.filter(
        notification => notification.key !== action.key,
      );
    },
    getAll: (state, action) => {
      state.loading = true;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.unread = action.payload.unread;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
    },
    addNotification: (state, action) => {
      state.unread++;
      state.data.unshift(action.payload);
    },
    readAll: (state, action) => {
      state.loading = true;
    },
    readAllSuccess: (state, action) => {
      state.loading = false;
      state.unread = 0;
      state.data = state.data.map(item => ({ ...item, read_at: true }));
    },
    readAllFailed: (state, action) => {
      state.loading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = pageSlice;

export const { enqueueSnackbar, closeSnackbar, removeSnackbar, addNotification } = actions;

export const selectors = {
  unreadCount: createSelector(
    state => state.notistack.unread,
    data => data,
  ),
  getNoti: createSelector(
    state => state.notistack.data,
    data => data,
  ),
};

export const getNotifications = () => async (dispatch, getState) => {
  dispatch(actions.getAll());
  try {
    const response = await Notification.getAll();
    if (response.success) {
      dispatch(actions.getAllSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error));
  }
};

export const markAllRead = () => async (dispatch, getState) => {
  dispatch(actions.readAll());
  try {
    const response = await Notification.readAll();
    if (response.success) {
      dispatch(actions.readAllSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.readAllFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
