import SuperFetch, { SuperUploader } from '../api/SuperFetch';

class Profile {
  getProfile() {
    return SuperFetch('merchant/profile');
  }

  editProfile(values) {
    return SuperFetch('merchant/profile/information', {
      method: 'POST',
      body: JSON.stringify(values),
    });
  }

  getSpecialities(search) {
    return SuperFetch(`merchant/profile/specialities?q=${search}`);
  }

  addSpeciality(values) {
    return SuperFetch(`merchant/profile/specialities`, {
      method: 'POST',
      body: JSON.stringify(values),
    });
  }

  deleteSpeciality(name) {
    return SuperFetch(`merchant/profile/specialities?name=${name}`, {
      method: 'DELETE',
    });
  }

  getTags(q) {
    return SuperFetch(`merchant/profile/tags?q=${q}`);
  }

  addTags(values) {
    return SuperFetch('merchant/profile/tags', {
      method: 'POST',
      body: JSON.stringify(values),
    });
  }

  deleteTags(name) {
    return SuperFetch(`merchant/profile/tags?name=${name}`, {
      method: 'DELETE',
    });
  }

  uploadProfile(formData) {
    return SuperUploader('merchant/profile/picture', formData);
  }

  uploadCover(formData) {
    return SuperUploader('merchant/profile/cover', formData);
  }
}

export default new Profile();
