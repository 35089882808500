import React, { useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';

import { uploadProfile, uploadCover, selectors } from '../../modules/profile';

// import profilePic from '../../assets/profile-pic.png';

const useStyle = makeStyles(theme => ({
  titeText: {
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 0px 5px rgba(0,0,0,0.4)',
  },
  profile: {
    borderRadius: '50%',
    width: '200px',
    height: '200px',
    overflow: 'hidden',
    '& >img': {
      width: '100%',
      height: '100%',
    },
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginTop: theme.spacing(6),
    },
  },
  changeProfile: {
    position: 'relative',
    height: '50%',
    top: '-35%',
    background: 'rgba(0,0,0,0.3)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cameraIcon: {
    color: 'white',
    margin: '0 auto',
    textAlign: 'center',
  },
  button: {
    background: 'rgba(0,0,0,0.3)',
    color: 'white',
    position: 'absolute',
    top: 0,
    right: 0,
    fontWeight: 'bold',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  info: {
    height: '200px',
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  profileProgress: {
    position: 'absolute',
    top: '40%',
    left: '40%',
  },
}));

export default function ProfileCover({ profile, name, likeCount }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { profileUploading } = useSelector(selectors.getProfile);

  // Profile Drop functions
  const onProfileDrop = useCallback(
    acceptedFiles => {
      // setImageUpload(acceptedFiles[0]);
      const formData = new FormData();
      formData.append('image', acceptedFiles[0]);
      dispatch(uploadProfile(formData));
    },
    [dispatch],
  );
  const { getRootProps: getProfileRootProps, getInputProps: profileInputProps } = useDropzone({
    onDrop: onProfileDrop,
    multiple: false,
    accept: 'image/*',
  });
  const { ref: profileRef, ...profileRootProps } = getProfileRootProps();

  // Cover Drop functions
  const onCoverDrop = useCallback(
    acceptedFiles => {
      // setImageUpload(acceptedFiles[0]);
      const formData = new FormData();
      formData.append('image', acceptedFiles[0]);
      dispatch(uploadCover(formData));
    },
    [dispatch],
  );
  const { getRootProps: getCoverRootProps, getInputProps: coverInputProps } = useDropzone({
    onDrop: onCoverDrop,
    multiple: false,
    accept: 'image/*',
  });
  const { ref: coverRef, ...coverRootProps } = getCoverRootProps();

  return (
    <>
      <div className={classes.profile}>
        {profileUploading && <CircularProgress className={classes.profileProgress} />}

        <img src={profile} alt="" />
        <RootRef rootRef={profileRef}>
          {/* <Paper  className={classes.dropzone}>
          
          <Button size="small" color="primary" variant="outlined">
            Select Photos
          </Button>
        </Paper> */}
          <div {...profileRootProps} className={classes.changeProfile}>
            <input {...profileInputProps()} />
            <PhotoCamera className={classes.cameraIcon} />
            <p style={{ color: 'white', textAlign: 'center', margin: '5px', fontWeight: 'bold' }}>
              Change
            </p>
          </div>
        </RootRef>
      </div>
      <div className={classes.info}>
        <Typography className={classes.titeText} variant="h3" gutterBottom>
          {name}
        </Typography>
        <Typography className={classes.titeText} variant="h4" gutterBottom>
          Liked by {likeCount} people
        </Typography>
      </div>
      <RootRef rootRef={coverRef}>
        {/* <Paper  className={classes.dropzone}>
          
          <Button size="small" color="primary" variant="outlined">
            Select Photos
          </Button>
        </Paper> */}
        {/* <div  className={classes.changecover}> */}
        <Button {...coverRootProps} className={classes.button}>
          <input {...coverInputProps()} />
          <PhotoCamera className={classes.buttonIcon} /> Change Cover Photo
        </Button>
        {/* </div> */}
      </RootRef>
    </>
  );
}
