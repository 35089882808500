import React, { useEffect } from 'react';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoCard from './InfoCard';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useForm from 'react-hook-form';

// const useStyle = makeStyles(theme => ({}))

export default function About({ description, onSubmit, submitting }) {
  // const classes = useStyle();
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!submitting) {
      setOpen(false);
    }
  }, [submitting]);

  return (
    <>
      <InfoCard
        title="About Business"
        rightItem={
          <Button color="primary" onClick={handleClickOpen}>
            Edit
          </Button>
        }
      >
        <Typography variant="body1">
          {description || 'No description found! Edit your description now.'}
        </Typography>
      </InfoCard>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit - About Business</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Write a cool description for your business. This will be shown to the users in our app.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus
              id="description"
              name="description"
              defaultValue={description}
              multiline
              rowsMax="4"
              rows="4"
              label="About Business"
              type="text"
              error={errors.description}
              inputRef={register({ required: true, min: 20, max: 150 })}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
