export const baseUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.happihub.com/api/v1'
    : 'https://api.happihub.com/api/v1';

export const socketHost =
  process.env.REACT_APP_ENV === 'production' ? 'socket.happihub.com' : 'socket.happihub.xyz';

export const socketAuth =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.happihub.com/broadcast/merchant'
    : 'https://api.happihub.xyz/api/broadcasting/auth';
