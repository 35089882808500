import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import { getSingleReview, selectors, likeReview, dislikeReview, postComment } from 'modules/review';
import ReviewCard from './review/ReviewCard';

const useStyle = makeStyles(theme => ({
  root: {
    maxWidth: 600,
  },
  loading: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'red',
    // fontWeight: 'bold',
    fontSize: '1.5rem',
    flexDirection: 'column',
  },
}));

export default function SingleReview({ match }) {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyle();
  const dispatch = useDispatch();
  const { single, posting, loading, error } = useSelector(selectors.getReview);

  const handleLikeReview = id => {
    dispatch(likeReview(id));
  };

  const handleDislikeReview = id => {
    dispatch(dislikeReview(id));
  };

  const handlePostComment = comment => {
    dispatch(postComment(comment));
  };

  useEffect(() => {
    dispatch(getSingleReview(match.params.id));
  }, [dispatch, match.params.id]);

  const handleBack = () => {
    history.push('/review');
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return (
      <div className={classes.loading}>
        <p>Sorry! Cannot find review you are looking for :(</p>
        <Button variant="contained" onClick={handleBack}>
          Go Back to Reviews
        </Button>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <ReviewCard
        onSubmit={handlePostComment}
        like={handleLikeReview}
        dislike={handleDislikeReview}
        data={single}
        posting={posting}
      />
    </div>
  );
}
