import React from 'react';

const SvgProfile = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="fill">
      <path d="M362.28 277.08H293.7c-18.77 0-34.03 15.26-34.03 34.03v52.59c0 18.77 15.26 34.04 34.03 34.04h68.58c18.77 0 34.03-15.26 34.03-34.04v-52.59c0-18.76-15.27-34.03-34.03-34.03zm9.28 86.63c0 5.16-4.23 9.28-9.28 9.28H293.7c-5.16 0-9.28-4.23-9.28-9.28v-52.59c0-5.16 4.22-9.28 9.28-9.28h68.58c5.16 0 9.28 4.23 9.28 9.28v52.59zm0 0" />
      <path d="M508.41 181.48c0-2.17-.51-4.23-1.65-6.08l-75.07-132c-2.17-3.81-6.29-6.29-10.73-6.29H91.06c-4.44 0-8.56 2.37-10.73 6.29l-74.97 132c-1.03 1.85-1.65 3.92-1.65 6.08 0 29.6 17.84 54.97 43.32 66.21v217.7c0 6.81 5.57 12.38 12.37 12.38h393.22c6.81 0 12.38-5.57 12.38-12.38V249.24c0-.51 0-.93-.1-1.34 25.56-11.24 43.51-36.72 43.51-66.42zM98.27 61.96h315.46l58.68 103.23H39.7L98.27 61.96zM362.9 189.84c-3.92 22.28-23.41 39.19-46.82 39.19s-42.9-16.91-46.82-39.19h93.64zm-119.94 0c-3.92 22.28-23.41 39.19-46.82 39.19s-42.9-16.91-46.92-39.19h93.74zm-213.68 0h93.74c-3.92 22.28-23.51 39.19-46.92 39.19-23.41.1-42.9-16.91-46.82-39.19zm182.33 263.18h-71.16V319.47c0-9.69 7.84-17.63 17.64-17.63h35.99c9.69 0 17.63 7.84 17.63 17.63v133.55h-.1zm228.63 0H236.36V319.47c0-23.3-18.97-42.38-42.38-42.38h-35.99c-23.3 0-42.38 18.97-42.38 42.38v133.65H71.77V253.77c1.45.11 2.88.11 4.33.11 24.96 0 47.03-12.68 60.02-31.97 13 19.29 35.07 31.97 60.02 31.97 24.96 0 46.92-12.68 60.02-31.97 13 19.29 35.06 31.97 59.92 31.97 24.96 0 46.92-12.68 59.92-31.97 12.99 19.29 35.06 31.97 60.02 31.97 1.45 0 2.79-.11 4.23-.11v199.25zm-4.23-223.89c-23.41 0-42.9-16.91-46.82-39.19h93.75c-4.03 22.18-23.52 39.19-46.93 39.19zm0 0" />
    </g>
  </svg>
);

export default SvgProfile;
