import React, { useEffect } from 'react';
import InfoCard from './InfoCard';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import FaceIcon from '@material-ui/icons/Face';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useForm from 'react-hook-form';

const useStyle = makeStyles(theme => ({
  icon: {
    color: '#6C6C6C',
  },
}));

export default function BusinessInfo({ data, submitting, onSubmit }) {
  const classes = useStyle();
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!submitting) {
      setOpen(false);
    }
  }, [submitting]);

  return (
    <>
      <InfoCard
        title="Business Information"
        rightItem={
          <Button onClick={handleClickOpen} color="primary">
            Edit
          </Button>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <WhereToVoteIcon className={classes.icon} fontSize="large" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">Location</Typography>
            <Typography variant="body1" color="primary">
              {data.merchant_info.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <PhoneIcon className={classes.icon} fontSize="large" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">Phone/Mobile Numbers</Typography>
            <Typography variant="body1" color="primary">
              {data.phone_no}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <PublicIcon className={classes.icon} fontSize="large" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">Website Address</Typography>
            <Typography variant="body1" color="primary">
              {data.merchant_info.website}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FaceIcon className={classes.icon} fontSize="large" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">Facebook Page Address</Typography>
            <Typography variant="body1" color="primary">
              {data.merchant_info.fb_page}
            </Typography>
          </Grid>
        </Grid>
      </InfoCard>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit - Business Info</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Write a cool description for your business. This will be shown to the users in our app.
          </DialogContentText> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="address"
              name="address"
              defaultValue={data.merchant_info.address}
              label="Address"
              multiline
              rows="3"
              type="text"
              error={errors.address}
              inputRef={register({ max: 200 })}
              fullWidth
              style={{ marginBottom: '1.5rem' }}
            />
            <TextField
              id="fb_page"
              name="fb_page"
              defaultValue={data.merchant_info.fb_page}
              label="Facebook Page"
              type="text"
              error={errors.fb_page}
              inputRef={register({ max: 150 })}
              fullWidth
              style={{ marginBottom: '1.5rem' }}
            />
            <TextField
              id="website"
              name="website"
              defaultValue={data.merchant_info.website}
              label="Website"
              type="text"
              error={errors.website}
              inputRef={register({ max: 150 })}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={submitting}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
