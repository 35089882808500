import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D8D8D8',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function InfoCard({ title, rightItem, children }) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <div>{rightItem}</div>
      </div>
      {children}
    </Paper>
  );
}
