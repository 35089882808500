import { createSlice, createSelector } from 'redux-starter-kit';
import Profile from '../repositories/Profile';
import { enqueueSnackbar } from './notifications';

const profileSlice = createSlice({
  slice: 'profile',
  initialState: {
    loading: false,
    submitting: false,
    error: '',
    profileUploading: false,
    coverUploading: false,
    data: {
      merchant_info: {},
      specialities: [],
      tags: [],
      rating: {
        rating: 5,
        revewed: 0,
      },
    },
  },
  reducers: {
    getProfile: (state, action) => {
      state.loading = true;
    },
    getProfileSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getProfileFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    editProfile: (state, action) => {
      state.submitting = true;
    },
    editProfileSuccess: (state, action) => {
      state.submitting = false;
      state.data.merchant_info.description = action.payload.data.merchant_info.description;
      state.data.merchant_info.address = action.payload.data.merchant_info.address;
      state.data.merchant_info.fb_page = action.payload.data.merchant_info.fb_page;
      state.data.merchant_info.website = action.payload.data.merchant_info.website;
    },
    editProfileFailed: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },

    addSpeciality: (state, action) => {
      state.submitting = true;
    },
    addSpecialitySuccess: (state, action) => {
      state.submitting = false;
      state.data.specialities.push(action.payload.data);
    },
    addSpecialityFailed: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },

    addTags: (state, action) => {
      state.submitting = true;
    },
    addTagsSuccess: (state, action) => {
      state.submitting = false;
      state.data.tags.push(action.payload.data);
    },
    addTagsFailed: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },

    deleteSpeciality: (state, action) => {
      state.submitting = true;
    },
    deleteSpecialitySuccess: (state, action) => {
      state.submitting = false;
      state.data.specialities = state.data.specialities.filter(
        item => action.payload.data.name !== item.name,
      );
    },
    deleteSpecialityFailed: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },

    deleteTags: (state, action) => {
      state.submitting = true;
    },
    deleteTagsSuccess: (state, action) => {
      state.submitting = false;
      state.data.tags = state.data.tags.filter(item => action.payload.data.name !== item.name);
    },
    deleteTagsFailed: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },

    uploadProfile: (state, action) => {
      state.profileUploading = true;
    },
    uploadProfileSuccess: (state, action) => {
      state.profileUploading = false;
      state.data.profile_picture = action.payload.data;
    },
    uploadProfileFailed: (state, action) => {
      state.profileUploading = false;
      state.error = action.payload;
    },

    uploadCover: (state, action) => {
      state.coverUploading = true;
    },
    uploadCoverSuccess: (state, action) => {
      state.coverUploading = false;
      state.data.cover_picture = action.payload.data;
    },
    uploadCoverFailed: (state, action) => {
      state.coverUploading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = profileSlice;

export const selectors = {
  getProfile: createSelector(
    state => state.profile,
    data => data,
  ),
};

export const getProfile = () => async (dispatch, getState) => {
  dispatch(actions.getProfile());
  try {
    const response = await Profile.getProfile();
    if (response.success) {
      dispatch(actions.getProfileSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to load profile.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.getProfileFailed(error));
  }
};

export const editProfile = values => async (dispatch, getState) => {
  dispatch(actions.editProfile());
  try {
    const response = await Profile.editProfile(values);
    if (response.success) {
      dispatch(actions.editProfileSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Profile updated successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to update profile.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.editProfileFailed(error));
  }
};

// export const getSpecialities = q => async (dispatch, getState) => {
//   dispatch(actions.getSpecialities());
//   try {
//     const response = await Profile.getSpecialities(q);
//     if (response.success) {
//       dispatch(actions.addSpecialitySuccess(response));
//       dispatch(
//         enqueueSnackbar({
//           message: 'Speciality added successfully.',
//           options: {
//             key: new Date().getTime() + Math.random(),
//             variant: 'success',
//           },
//         }),
//       );
//     } else {
//       throw Error(response.message);
//     }
//   } catch (error) {
//     console.log(error);
//     dispatch(
//       enqueueSnackbar({
//         message: 'Failed to add speciality.',
//         options: {
//           key: new Date().getTime() + Math.random(),
//           variant: 'error',
//         },
//       }),
//     );
//     dispatch(actions.addSpecialityFailed(error));
//   }
// };

export const addSpeciality = values => async (dispatch, getState) => {
  dispatch(actions.addSpeciality());
  try {
    const response = await Profile.addSpeciality(values);
    if (response.success) {
      dispatch(actions.addSpecialitySuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Speciality added successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to add speciality.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.addSpecialityFailed(error));
  }
};

export const deleteSpeciality = values => async (dispatch, getState) => {
  dispatch(actions.deleteSpeciality());
  try {
    const response = await Profile.deleteSpeciality(values);
    if (response.success) {
      dispatch(actions.deleteSpecialitySuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Speciality deleted successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to delete speciality.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.deleteSpecialityFailed(error));
  }
};

export const addTags = values => async (dispatch, getState) => {
  dispatch(actions.addTags());
  try {
    const response = await Profile.addTags(values);
    if (response.success) {
      dispatch(actions.addTagsSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Tag added successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to add tag.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.addTagsFailed(error));
  }
};

export const deleteTags = values => async (dispatch, getState) => {
  dispatch(actions.deleteTags());
  try {
    const response = await Profile.deleteTags(values);
    if (response.success) {
      dispatch(actions.deleteTagsSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Tag deleted successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to delete tag.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.deleteTagsFailed(error));
  }
};

export const uploadProfile = input => async (dispatch, getState) => {
  dispatch(actions.uploadProfile());
  try {
    const response = await Profile.uploadProfile(input);
    if (response.success) {
      dispatch(actions.uploadProfileSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed uploading profile photo.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.uploadProfileFailed(error));
  }
};

export const uploadCover = input => async (dispatch, getState) => {
  dispatch(actions.uploadCover());
  try {
    const response = await Profile.uploadCover(input);
    if (response.success) {
      dispatch(actions.uploadCoverSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed uploading cover photo.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.uploadCoverFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
