import { combineReducers } from 'redux';

import auth from '../modules/auth';
import cashback from '../modules/cashback';
import customers from '../modules/customers';
import income from '../modules/income';
import voucher from '../modules/voucher';
import menu from '../modules/menu';
import review from '../modules/review';
import profile from '../modules/profile';
import page from '../modules/page';
import billing from '../modules/billing';
import notistack from '../modules/notifications';

export default combineReducers({
  auth,
  cashback,
  customers,
  income,
  voucher,
  menu,
  review,
  profile,
  notistack,
  page,
  billing,
});
