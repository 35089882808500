import React from 'react';

const SvgDrawerClose = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="fill"
      d="M504.46 124.69H7.35c-1.09 0-1.97-.88-1.97-1.97V76.55c0-1.09.88-1.97 1.97-1.97h497.11c1.09 0 1.97.88 1.97 1.97v46.16c0 1.09-.88 1.98-1.97 1.98zM504.41 282.46H155.47a2.02 2.02 0 0 1-2.02-2.02v-46.06c0-1.12.91-2.02 2.02-2.02H504.4c1.12 0 2.02.91 2.02 2.02v46.06c.01 1.11-.9 2.02-2.01 2.02zM504.41 439.43H7.4c-1.12 0-2.02-.9-2.02-2.02v-46.06c0-1.12.9-2.02 2.02-2.02h497.01c1.12 0 2.02.9 2.02 2.02v46.06c0 1.11-.91 2.02-2.02 2.02zM103.13 200.79h1.35c3.62 1.24 4.75 3.87 4.74 7.56-.07 33.49-.04 66.98-.04 100.47 0 .45.01.9-.01 1.35-.14 2.78-1.98 5.01-4.7 5.55-.63.12-1.44.01-2-.3a72602.67 72602.67 0 0 1-94.38-52.25c-.53-.3-1.04-.75-1.4-1.25-2.17-3.05-1.65-6.53 1.47-8.62 3.04-2.04 6.17-3.98 9.38-5.74 24.73-13.58 49.48-27.11 74.23-40.65 3.78-2.05 7.58-4.07 11.36-6.12z"
    />
  </svg>
);

export default SvgDrawerClose;
