import { createSlice, createSelector } from 'redux-starter-kit';
import Cashback from '../repositories/Cashback';

const cashbackSlice = createSlice({
  slice: 'cashback',
  initialState: {
    list: {
      loading: false,
      error: '',
      data: [],
    },
    stats: {
      loading: false,
      error: '',
      data: {},
    },
    graph: {
      loading: false,
      error: '',
      data: {},
    },
  },
  reducers: {
    getCashback: (state, action) => {
      state.list.loading = true;
    },
    getCashbackSuccess: (state, action) => {
      state.list.loading = false;
      state.list.error = false;
      state.list.data = action.payload.data;
    },
    getCashbackFailed: (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload;
    },

    getCashbackStats: (state, action) => {
      state.stats.loading = true;
    },
    getCashbackStatsSuccess: (state, action) => {
      state.stats.loading = false;
      state.stats.error = false;
      state.stats.data = action.payload.data;
    },
    getCashbackStatsFailed: (state, action) => {
      state.stats.loading = false;
      state.stats.error = action.payload;
    },

    getCashbackGraph: (state, action) => {
      state.graph.loading = true;
    },
    getCashbackGraphSuccess: (state, action) => {
      state.graph.loading = false;
      state.graph.error = false;
      state.graph.data = action.payload.data;
    },
    getCashbackGraphFailed: (state, action) => {
      state.graph.loading = false;
      state.graph.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = cashbackSlice;

export const graphDataSelector = createSelector(
  state => state.cashback.graph.data,
  data => {
    return Object.keys(data).map(key => ({
      name: key,
      value: data[key],
    }));
  },
);

export const selectors = {
  getCashback: createSelector(
    state => state.cashback,
    data => data,
  ),
};

export const getCashback = (query = {}) => async (dispatch, getState) => {
  dispatch(actions.getCashback());
  try {
    const response = await Cashback.getAll(query);
    if (response.success) {
      dispatch(actions.getCashbackSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getCashbackFailed(error));
  }
};

export const getCashbackStats = () => async (dispatch, getState) => {
  dispatch(actions.getCashbackStats());
  try {
    const response = await Cashback.getStatistics();
    if (response.success) {
      dispatch(actions.getCashbackStatsSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getCashbackStatsFailed(error));
  }
};

export const getCashbackGraph = days => async (dispatch, getState) => {
  dispatch(actions.getCashbackGraph());
  try {
    const response = await Cashback.getGraphHistory(days);
    if (response.success) {
      dispatch(actions.getCashbackGraphSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getCashbackGraphFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
