import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CommentIcon from '@material-ui/icons/Comment';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from '../../modules/auth';

import ImageModal from '../../components/ImageModal';

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: theme.spacing(42),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  mediaGrid: {
    // height: '300px',
    overflow: 'hidden',
  },
  // imageBox: {
  //   maxWidth: 100,
  //   [theme.breakpoint.down('sm')]: {

  //   }
  // },
  media: {
    width: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    // // maxWidth: 360,
  },
  avatar: {
    backgroundColor: red[500],
  },
  root: {
    width: '100%',
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    // color: '#4a4a4a',
    margin: theme.spacing(1),
  },
  leftIcon: {
    // color: '#4a4a4a',
    marginRight: theme.spacing(1),
  },
  // primaryBtn: {
  //   color: theme.palette.primary.main,
  //   $leftIcon: {
  //     color: theme.palette.primary.main,
  //   },
  // },
  action: {
    margin: 0,
  },
  chip: props => ({
    backgroundColor: props.rating < 4 ? '#FFA800' : '#27AE60',
    color: '#FFF',
    fontWeight: 'bold',
  }),
  textField: {
    margin: theme.spacing(2),
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'space-evenly',
  },
}));

export default function ReviewCard({ data = {}, like, dislike, onSubmit = null, posting }) {
  const classes = useStyles(data);
  const { customer = {}, images = [] } = data;
  const { register, handleSubmit, errors, setValue } = useForm();
  const { data: authData } = useSelector(selectors.getAuth);

  const handleOnSubmit = value => {
    if (onSubmit) {
      onSubmit({
        review_id: data.id,
        body: value.comment,
      });
      setValue('comment', '');
    }
  };

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  function handleClickOpen(e, url) {
    e.preventDefault();
    setImageUrl(url);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const getImgBoxHeight = ln => {
    return ln < 3 ? 400 : 200;
  };

  const getImgGridCount = ln => {
    return ln > 3 ? 4 : 12 / ln;
  };

  return (
    <Card className={classes.card}>
      <ImageModal src={imageUrl} open={open} handleClose={handleClose} />
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar} src={customer.profile_picture} />
        }
        action={<Chip className={classes.chip} label={`Rating ★ ${data.rating}`} />}
        title={customer.name}
        subheader="2 months ago"
        classes={{
          action: classes.action,
        }}
      />
      <CardContent>
        <Typography variant="body1" color="textPrimary" component="p">
          {data.body}
        </Typography>
      </CardContent>
      <Grid container spacing={1} className={classes.mediaGrid}>
        {images &&
          images.length > 0 &&
          images.map(img => (
            // <CardMedia className={classes.media} image={images[0]} title="Paella dish" />
            <Grid
              key={img}
              item
              sm={getImgGridCount(images.length)}
              md={getImgGridCount(images.length)}
              lg={getImgGridCount(images.length)}
              onClick={e => handleClickOpen(e, img)}
              zeroMinWidth
              // className={classes.imageBox}
              style={{
                height: getImgBoxHeight(images.length),
                overflow: 'hidden',
              }}
            >
              <img src={img} alt="" className={classes.media} />
            </Grid>
          ))}
      </Grid>
      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          variant="text"
          color={data.merchant_reaction === 'like' ? 'primary' : 'default'}
          classes={{ root: classes.button, outlinedPrimary: classes.primaryBtn }}
          onClick={() => like(data.id)}
        >
          <ThumbUpIcon className={classes.leftIcon} />
          Cool ({data.positive_reactions_count})
        </Button>
        <Button
          variant="text"
          color={data.merchant_reaction === 'dislike' ? 'primary' : 'default'}
          classes={{ root: classes.button, outlinedPrimary: classes.primaryBtn }}
          onClick={() => dislike(data.id)}
        >
          <ThumbDownIcon className={classes.leftIcon} />
          Bogus ({data.negative_reactions_count})
        </Button>
        <Button
          variant="text"
          classes={{ root: classes.button, outlinedPrimary: classes.primaryBtn }}
          component={Link}
          to={`/review/${data.id}`}
        >
          <CommentIcon className={classes.leftIcon} />
          Comments ({data.comments_count || ((data.comments && data.comments.length) || 0)})
        </Button>
      </CardActions>
      {data.comments && (
        <form style={{ display: 'flex' }} onSubmit={handleSubmit(handleOnSubmit)}>
          <TextField
            id="comment"
            name="comment"
            label="Comment"
            multiline
            rows="2"
            maxRows="4"
            variant="outlined"
            // helperText={errors.comment}
            inputRef={register({ required: true, min: 5, max: 250 })}
            className={classes.textField}
            error={errors.comment}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={posting || errors.comment}
                    variant="outlined"
                    color="primary"
                    type="submit"
                  >
                    Post
                  </Button>
                </InputAdornment>
              ),
            }}
            // value={values.name}
            // onChange={handleChange('name')}
            // margin="normal"
          />
        </form>
      )}
      {data.comments && data.comments.length > 0 && (
        <List className={classes.root}>
          {data.comments.map(comment => (
            <React.Fragment key={comment.id}>
              <ListItem
                alignItems="flex-start"
                style={{ background: comment.new ? '#fff68f' : '#fff' }}
              >
                {comment.commentator_type === 'merchant' ? (
                  <>
                    <ListItemAvatar>
                      <Avatar alt={authData.name} src={authData.profile_picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={authData.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {comment.body}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </>
                ) : (
                  <>
                    <ListItemAvatar>
                      <Avatar
                        alt={comment.commentator.name}
                        src={comment.commentator.profile_picture}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={comment.commentator.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {comment.body}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </>
                )}
              </ListItem>
              <Divider variant="middle" component="div" />
            </React.Fragment>
          ))}
        </List>
      )}
    </Card>
  );
}
