import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getVoucherRedeems, acceptVoucherRedeem } from '../../modules/voucher';

// import smallAvatar from '../../assets/avatar.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #D8D8D8',
  },
  rowTop: {
    display: 'flex',
  },
  nameTime: {
    marginLeft: theme.spacing(1),
  },
  amount: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  amountText: {
    alignSelf: 'center',
    color: '#2196F3',
    fontWeight: 'bold',
    fontSize: '32px',
    marginTop: theme.spacing(2),
  },
  rowBottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  link: {
    textAlign: 'center',
    color: '#2196F3',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  voucherName: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '10px 0',
  },
  avatar: {
    marginTop: '5px',
  },
}));

export default function VoucherClaimCard() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const voucherRedeems = useSelector(state => state.voucher.redeems.data);
  const voucherAcceptLoading = useSelector(state => state.voucher.accept.loading);

  useEffect(() => {
    dispatch(getVoucherRedeems({ status: 'REQUESTED', page: 1, limit: 5 }));
  }, [dispatch]);

  const acceptVoucher = id => {
    dispatch(acceptVoucherRedeem(id));
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Coupon/Gift Card Redeem
      </Typography>
      {voucherRedeems.length ? (
        voucherRedeems.map(item => (
          <div className={classes.row} key={item.id}>
            <div className={classes.rowTop}>
              <Avatar
                alt="Remy Sharp"
                src={item.customer.profile_picture}
                className={classes.avatar}
              />
              <div className={classes.nameTime}>
                <Typography variant="body1" display="block" style={{ fontWeight: 'bold' }}>
                  {item.customer.name}
                </Typography>
                <Typography variant="body2" display="block" gutterBottom>
                  {format(parseISO(item.created_at), 'do LLL, yyyy')}
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                className={classes.amountText}
                variant="h4"
                align="center"
                display="block"
              >
                ৳{item.voucher.value}
              </Typography>
              <Typography
                className={classes.voucherName}
                variant="body2"
                align="center"
                display="block"
              >
                ৳{item.voucher.name}
              </Typography>
            </div>
            <div className={classes.rowBottom}>
              {item.status === 'REQUESTED' ? (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={voucherAcceptLoading[item.id]}
                  onClick={() => acceptVoucher(item.id)}
                >
                  Accept
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  Accepted
                </Button>
              )}
            </div>
          </div>
        ))
      ) : (
        <Typography align="center" variant="subtitle1" gutterBottom>
          No Voucher Claim Found!
        </Typography>
      )}

      <div className={classes.link}>
        <Link className={classes.link} to="/voucher-income">
          See All
        </Link>
      </div>
    </Paper>
  );
}
