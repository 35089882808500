import { baseUrl } from './apiConfig';

export const getAuth = () => {
  const id = localStorage.getItem('happihub_merchant_id');
  const token = localStorage.getItem('happihub_merchant_token');
  return {
    id,
    token,
  };
};

export const clearAuth = () => {
  localStorage.clear();
  window.location.reload(false);
};

export default async (url, optionsProps = {}) => {
  const options = {
    method: 'GET',
    mode: 'cors',
    ...optionsProps,
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...optionsProps.headers,
    }),
  };

  try {
    const response = await fetch(`${baseUrl}/${url}`, options);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  } catch (error) {
    console.log('---AUTH ERROR---', error);
  }
};
