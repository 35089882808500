import { createSlice, createSelector } from 'redux-starter-kit';
import Menu from '../repositories/Menu';
import { enqueueSnackbar } from './notifications';
import { browserHistory } from '../lib/routes';

const menuSlice = createSlice({
  slice: 'menu',
  initialState: {
    loading: false,
    deleting: false,
    error: '',
    data: [],
    single: {},
  },
  reducers: {
    getMenuList: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    getMenuListSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = '';
    },
    getMenuListFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    getSingleMenu: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    getSingleMenuSuccess: (state, action) => {
      state.loading = false;
      state.single = action.payload.data;
      state.error = '';
    },
    getSingleMenuFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    uploadMenu: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    uploadMenuSuccess: (state, action) => {
      state.loading = false;
      state.data.unshift(action.payload.data);
    },
    uploadMenuFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    editMenu: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    editMenuSuccess: (state, action) => {
      state.loading = false;
      state.single = action.payload.data;
      state.data = state.data.map(menu => {
        if (menu.id === action.payload.data.id) {
          return action.payload.data;
        }
        return menu;
      });
    },
    editMenuFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    deleteMenu: (state, action) => {
      state.deleting = true;
      state.error = '';
    },
    deleteMenuSuccess: (state, action) => {
      state.deleting = false;
      state.data = state.data.filter(menu => menu.id !== action.payload);
      state.error = '';
    },
    deleteMenuFailed: (state, action) => {
      state.deleting = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = menuSlice;

export const selectors = {
  getMenu: createSelector(
    state => state.menu,
    data => data,
  ),
  getSingleMenu: createSelector(
    state => state.menu.single,
    data => data,
  ),
};

export const getMenuList = () => async (dispatch, getState) => {
  dispatch(actions.getMenuList());
  try {
    const response = await Menu.getMenuList();
    if (response.success) {
      dispatch(actions.getMenuListSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getMenuListFailed(error));
  }
};

export const getSingleMenu = id => async (dispatch, getState) => {
  dispatch(actions.getSingleMenu());
  try {
    const response = await Menu.getSingleMenu(id);
    if (response.success) {
      dispatch(actions.getSingleMenuSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getSingleMenuFailed(error));
  }
};

export const deleteMenu = id => async (dispatch, getState) => {
  dispatch(actions.deleteMenu());
  try {
    const response = await Menu.deleteMenu(id);
    if (response.success) {
      dispatch(actions.deleteMenuSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Menu deleted successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.deleteMenuFailed(error));
  }
};

export const uploadMenu = input => async (dispatch, getState) => {
  dispatch(actions.uploadMenu());
  try {
    const response = await Menu.uploadMenu(input);
    if (response.success) {
      dispatch(actions.uploadMenuSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Menu uploaded successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return response;
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to upload menu.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.uploadMenuFailed(error));
    return {
      error: true,
    };
  }
};

export const editMenu = (id, input) => async (dispatch, getState) => {
  dispatch(actions.editMenu());
  try {
    const response = await Menu.editMenu(id, input);
    if (response.success) {
      dispatch(actions.editMenuSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Menu edited successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      browserHistory.push('/menu');
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: 'Failed to edit menu.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    dispatch(actions.editMenuFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
