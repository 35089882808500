import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectors, markAllRead } from 'modules/notifications';

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: 400,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    backgroundColor: '#e8e8e8',
  },
  paper: {
    minWidth: 300,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  read: {
    backgroundColor: 'rgba(44, 130, 201, 0.4)',
  },
  link: {
    textAlign: 'right',
    marginBottom: theme.spacing(2),
  },
}));

const NotificationDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.getNoti);
  const classes = useStyles();

  const handleMarkAsRead = () => {
    dispatch(markAllRead());
  };
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Link href="#" onClick={handleMarkAsRead} className={classes.link}>
        Mark all as read
      </Link>
      {!!data.length &&
        data.map(item => (
          <Paper className={clsx(classes.paper, !item.read_at && classes.read)} key={item.id}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar src={item.image} />
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{item.title}</Typography>
                <Typography variant="caption">{item.message}</Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
    </Drawer>
  );
};

export default NotificationDrawer;
