import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';

import ReviewCard from './review/ReviewCard';
import { getReviews, likeReview, dislikeReview, selectors } from '../modules/review';

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Review() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectors.getReview);

  const handleLikeReview = id => {
    dispatch(likeReview(id));
  };

  const handleDislikeReview = id => {
    dispatch(dislikeReview(id));
  };

  useEffect(() => {
    dispatch(getReviews());
  }, [dispatch]);
  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
  if (!data.length) {
    return (
      <Typography align="center" variant="h5" gutterBottom>
        No Review Found
      </Typography>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {data.length &&
          data.map(review => (
            <ReviewCard
              key={review.id}
              like={handleLikeReview}
              dislike={handleDislikeReview}
              data={review}
            />
          ))}
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <ReviewCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <ReviewCard />
      </Grid> */}
    </Grid>
  );
}
