import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoCard from './InfoCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/lab/Rating';

// import service from '../../assets/service.png';
// import environment from '../../assets/environment.png';
import quality from '../../assets/quality.png';

const useStyle = makeStyles(theme => ({
  ratingHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: props => ({
    marginLeft: theme.spacing(1),
    backgroundColor: props.rating < 4 ? '#FFA800' : '#27AE60',
  }),
  ratingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  ratingIcon: props => ({
    color: props.rating < 4 ? '#FFA800' : '#27AE60',
  }),
}));

export default function BusinessRating({ data }) {
  const { rating, reviewed } = data;
  const classes = useStyle({ rating });

  return (
    <InfoCard
      title="Rating"
      rightItem={
        <div className={classes.ratingHeader}>
          <Typography variant="h6" color="primary" gutterBottom>
            Overall Rating:{' '}
          </Typography>
          <Chip size="small" label={rating} className={classes.chip} />
        </div>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs>
          <Box component="div" mb={3} className={classes.ratingBox} borderColor="transparent">
            <img src={quality} className={classes.icon} alt="" />
            <Typography variant="subtitle1"> Overall Rating</Typography>
            <Rating
              size="large"
              classes={{ iconFilled: classes.ratingIcon }}
              value={rating}
              readOnly
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box component="div" mb={3} className={classes.ratingBox} borderColor="transparent">
            <Typography variant="subtitle1">Reviewed by</Typography>
            <Typography variant="h2">{reviewed}</Typography>
          </Box>
        </Grid>
      </Grid>
    </InfoCard>
  );
}
