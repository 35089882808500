import { createSlice, createSelector } from 'redux-starter-kit';
import Dashboard from '../repositories/Dashboard';

const incomeSlice = createSlice({
  slice: 'income',
  initialState: {
    loading: false,
    error: '',
    data: {},
  },
  reducers: {
    getIncome: (state, action) => {
      state.loading = true;
    },
    getIncomeSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getIncomeFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = incomeSlice;

export const selectors = {
  getIncome: createSelector(
    state => state.income,
    data => data,
  ),
};

export const getIncome = () => async (dispatch, getState) => {
  dispatch(actions.getIncome());
  try {
    const response = await Dashboard.getIncome();
    if (response.success) {
      dispatch(actions.getIncomeSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getIncomeFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
