import { createSlice, createSelector } from 'redux-starter-kit';
import Review from '../repositories/Review';
import { enqueueSnackbar } from './notifications';

const reviewSlice = createSlice({
  slice: 'review',
  initialState: {
    loading: false,
    posting: false,
    error: '',
    data: [],
    single: {},
  },
  reducers: {
    getReviews: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    getReviewsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getReviewsFailed: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    getSingleReview: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    getSingleReviewSuccess: (state, action) => {
      state.loading = false;
      state.single = action.payload.data;
    },
    getSingleReviewFailed: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    postComment: (state, action) => {
      state.posting = true;
    },
    postCommentSuccess: (state, action) => {
      state.posting = false;
      state.single.comments.unshift({ ...action.payload.data, new: true });
    },
    postCommentFailed: (state, action) => {
      state.posting = false;
      state.error = action.payload;
    },

    likeReview: (state, action) => {
      // state.loading = true;
    },
    likeReviewSuccess: (state, action) => {
      // state.loading = false;
      if (state.single.id === action.payload.reacted_id) {
        if (state.single.merchant_reaction === 'like') {
          state.single.merchant_reaction = false;
          state.single.positive_reactions_count--;
        } else if (state.single.merchant_reaction === 'dislike') {
          state.single.merchant_reaction = action.payload.type;
          state.single.positive_reactions_count++;
          state.single.negative_reactions_count--;
        } else {
          state.single.merchant_reaction = action.payload.type;
          state.single.positive_reactions_count++;
        }
      }
      state.data.forEach(element => {
        if (element.id === action.payload.reacted_id) {
          if (element.merchant_reaction === 'like') {
            element.merchant_reaction = false;
            element.positive_reactions_count--;
          } else if (element.merchant_reaction === 'dislike') {
            element.merchant_reaction = action.payload.type;
            element.positive_reactions_count++;
            element.negative_reactions_count--;
          } else {
            element.merchant_reaction = action.payload.type;
            element.positive_reactions_count++;
          }
        }
      });
    },
    likeReviewFailed: (state, action) => {
      // state.loading = false;
      // state.error = action.payload;
    },

    dislikeReview: (state, action) => {
      // state.loading = true;
    },
    dislikeReviewSuccess: (state, action) => {
      // state.loading = false;
      if (state.single.id === action.payload.reacted_id) {
        if (state.single.merchant_reaction === 'dislike') {
          state.single.merchant_reaction = false;
          state.single.negative_reactions_count--;
        } else if (state.single.merchant_reaction === 'like') {
          state.single.merchant_reaction = action.payload.type;
          state.single.negative_reactions_count++;
          state.single.positive_reactions_count--;
        } else {
          state.single.merchant_reaction = action.payload.type;
          state.single.negative_reactions_count++;
        }
      }
      state.data.forEach(element => {
        if (element.id === action.payload.reacted_id) {
          if (element.merchant_reaction === 'dislike') {
            element.merchant_reaction = false;
            element.negative_reactions_count--;
          } else if (element.merchant_reaction === 'like') {
            element.merchant_reaction = action.payload.type;
            element.negative_reactions_count++;
            element.positive_reactions_count--;
          } else {
            element.merchant_reaction = action.payload.type;
            element.negative_reactions_count++;
          }
        }
      });
    },
    dislikeReviewFailed: (state, action) => {
      // state.loading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = reviewSlice;

export const selectors = {
  getReview: createSelector(
    state => state.review,
    data => data,
  ),
};

export const getReviews = () => async (dispatch, getState) => {
  dispatch(actions.getReviews());
  try {
    const response = await Review.getReviews();
    if (response.success) {
      dispatch(actions.getReviewsSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getReviewsFailed(error));
  }
};

export const getSingleReview = id => async (dispatch, getState) => {
  dispatch(actions.getSingleReview());
  try {
    const response = await Review.getSingleReview(id);
    if (response.success) {
      dispatch(actions.getSingleReviewSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getSingleReviewFailed(error));
  }
};

export const likeReview = id => async (dispatch, getState) => {
  dispatch(actions.likeReview());
  try {
    const response = await Review.likeReview(id);
    if (response.success) {
      dispatch(actions.likeReviewSuccess(response.data));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.likeReviewFailed(error));
  }
};

export const dislikeReview = id => async (dispatch, getState) => {
  dispatch(actions.dislikeReview());
  try {
    const response = await Review.dislikeReview(id);
    if (response.success) {
      dispatch(actions.dislikeReviewSuccess(response.data));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.dislikeReviewFailed(error));
  }
};

export const postComment = values => async (dispatch, getState) => {
  dispatch(actions.postComment());
  try {
    const response = await Review.postComment(values);
    if (response.success) {
      dispatch(actions.postCommentSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Comment posted successfully!',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.postCommentFailed(error));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to post comment.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};
// Export the reducer, either as a default or named export
export default reducer;
