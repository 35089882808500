import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import theme from './lib/theme';
import { browserHistory } from './lib/routes';
import Login from './pages/Login';
import Home from './pages';
import PrivateRoute from './components/PrivateRoute';
import Notifier from './components/Notifier';

const App = () => {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          action={key => (
            <IconButton onClick={onClickDismiss(key)} aria-label="close">
              <CancelIcon style={{ color: '#FFF' }} />
            </IconButton>
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Notifier />
          <Router history={browserHistory}>
            <Switch>
              <Route exacte path="/login" component={Login} />
              <PrivateRoute exacte path="/" component={Home} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default App;
