import BusinessSummary from '../icons/BusinessSummary';
import Profile from '../icons/Profile';
// import BillingDetails from '../icons/BillingDetails';
// import CashbackClaim from '../icons/CashbackClaim';
import VoucherIncome from '../icons/VoucherIncome';
// import ReferralIncome from '../icons/ReferralIncome';
import Review from '../icons/Review';
import Menu from '../icons/Menu';
import SwitchAccount from '../icons/SwitchAccount';
import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export default [
  {
    name: 'Business Summary',
    path: '/',
    Icon: BusinessSummary,
  },
  {
    name: 'Profile',
    path: '/profile',
    Icon: Profile,
  },
  // {
  //   name: 'Billing Details',
  //   path: '/billing-details',
  //   Icon: BillingDetails,
  // },
  // {
  //   name: 'Cashback Claim',
  //   path: '/cashback-claim',
  //   Icon: CashbackClaim,
  // },
  {
    name: 'Voucher',
    path: '/voucher',
    Icon: VoucherIncome,
  },
  // {
  //   name: 'Referral Income',
  //   path: '/referral-income',
  //   Icon: ReferralIncome,
  // },
  {
    name: 'Review',
    path: '/review',
    Icon: Review,
  },
  {
    name: 'Menu/Product/Service',
    path: '/menu',
    Icon: Menu,
  },
  {
    name: 'Switch Account',
    path: '/switch-account',
    Icon: SwitchAccount,
  },
];
