import { createSlice, createSelector } from 'redux-starter-kit';
import Dashboard from '../repositories/Dashboard';

const pageSlice = createSlice({
  slice: 'page',
  initialState: {
    loading: false,
    error: '',
    data: {
      monthly_sale: [],
    },
  },
  reducers: {
    getPageStats: (state, action) => {
      state.loading = true;
    },
    getPageStatsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getPageStatsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = pageSlice;

export const selectors = {
  getPage: createSelector(
    state => state.page,
    data => data,
  ),
  getGraphData: createSelector(
    state => state.page.data.monthly_sale,
    data => {
      return Object.keys(data).map(key => ({
        name: key,
        value: data[key],
      }));
    },
  ),
};

export const getPageStats = () => async (dispatch, getState) => {
  dispatch(actions.getPageStats());
  try {
    const response = await Dashboard.getPageStats();
    if (response.success) {
      dispatch(actions.getPageStatsSuccess(response));
    } else {
      throw Error(response.message);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getPageStatsFailed(error));
  }
};
// Export the reducer, either as a default or named export
export default reducer;
