import SuperFetch from '../api/SuperFetch';
import querystring from 'query-string';

class Notification {
  getAll(query = { page: 1, limit: 20 }) {
    return SuperFetch(`merchant/notifications?${querystring.stringify(query)}`);
  }
  readAll() {
    return SuperFetch('merchant/notifications/read', {
      method: 'POST',
    });
  }
}

export default new Notification();
