import React, { useEffect, useRef, useContext } from 'react';
import Echo from 'laravel-echo';
// import axios from 'axios';

import { socketHost, socketAuth } from 'api/apiConfig';
import { getAuth } from 'api/auth';
const pusher = require('pusher-js'); //eslint-disable-line

const EchoContext = React.createContext();

function EchoProvider({ children }) {
  const echo = useRef();
  const { id, token } = getAuth();

  useEffect(() => {
    window.Echo = echo.current = new Echo({
      broadcaster: 'pusher',
      key: 'happihub',
      wsHost: socketHost,
      wsPort: 6001,
      disableStats: true,
      enabledTransports: ['ws'],
      encrypted: false,
      authEndpoint: socketAuth,
      auth: {
        headers: {
          id,
          role: 'merchant',
          authorization: token,
        },
      },
    });
  }, [id, token]);
  return <EchoContext.Provider value={echo.current}>{children}</EchoContext.Provider>;
}

function useEcho() {
  const echo = useContext(EchoContext);

  return echo;
}

export { EchoProvider, useEcho };
export default EchoContext;
