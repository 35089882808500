import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import IconCard from './business-summary/IconCard';
import AvatarsCard from './business-summary/AvatarsCard';
import BadgeCard from './business-summary/BadgeCard';
import TotalSale from './business-summary/TotalSale';
import PageViews from './business-summary/PageViews';
import ColorCard from './business-summary/ColorCard';
import CashbackClaimTable from './business-summary/CashbackClaimTable';
import VoucherClaimCard from './business-summary/VoucherClaimCard';
import { getIncome } from '../modules/income';
import { getCashback } from '../modules/cashback';
import { getCustomers } from '../modules/customers';
import { getPageStats, selectors as pageSelectors } from '../modules/page';

import totalIncome from '../assets/total-income.png';
import paymentDue from '../assets/payment-due.png';

const useSyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

export default function BusinessSummary() {
  const classes = useSyles();

  const dispatch = useDispatch();
  const incomeData = useSelector(state => state.income.data);
  const incomeLoading = useSelector(state => state.income.loading);

  const cashbacks = useSelector(state => state.cashback.list.data);
  const cashbacksLoading = useSelector(state => state.cashback.list.loading);

  const customers = useSelector(state =>
    state.customers.data.latest_customers.map(c => c.profile_picture),
  );
  const customersLoading = useSelector(state => state.customers.loading);

  // const stats = useSelector(state => state.cashback.stats.data);
  const statsLoading = useSelector(state => state.cashback.stats.loading);

  const pageStats = useSelector(state => state.page.data);

  const graphData = useSelector(pageSelectors.getGraphData);

  useEffect(() => {
    dispatch(getIncome());
    dispatch(getCashback());
    dispatch(getCustomers());
    // dispatch(getCashbackStats());
    dispatch(getPageStats());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <VoucherClaimCard />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <TotalSale data={graphData} totalSales={incomeData.total_sales || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <PageViews viewCount={pageStats.view_count || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <IconCard
                loading={incomeLoading}
                primaryText="Total Income"
                secondaryText={incomeData.total_income || 0}
                icon={totalIncome}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <IconCard
                loading={statsLoading}
                primaryText="Happi Points Payment Due"
                secondaryText={incomeData.cashback_payment_due || 0}
                icon={paymentDue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <ColorCard
                    loading={incomeLoading}
                    primaryText={incomeData.voucher_income || 0}
                    secondaryText="Voucher Income"
                    color="#13C2C2"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ColorCard
                    loading={incomeLoading}
                    primaryText={incomeData.referral_income || 0}
                    secondaryText="Referral Income"
                    color="#77CC19"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ColorCard
                    loading={incomeLoading}
                    primaryText={parseInt(incomeData.average_bill || 0)}
                    secondaryText="Avg. Bill Range "
                    color="#2196F3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CashbackClaimTable loading={cashbacksLoading} data={cashbacks} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={4} xl={4}>
          <VoucherClaimCard />
        </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <BadgeCard pageLikes={pageStats.like_count || 0} />
            </Grid>

            <Grid item xs={12} sm={6} md={12} xl={12}>
              <AvatarsCard
                primaryText="New Customers"
                loading={customersLoading}
                avatars={customers}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
