import SuperFetch, { SuperUploader } from '../api/SuperFetch';

class Menu {
  getMenuList() {
    return SuperFetch(`merchant/menu?page=1`);
  }

  getSingleMenu(id) {
    return SuperFetch(`merchant/menu/${id}`);
  }

  uploadMenu(formData) {
    return SuperUploader('merchant/menu', formData);
  }

  editMenu(id, formData) {
    return SuperUploader(`merchant/menu/${id}`, formData);
  }

  deleteMenu(id) {
    return SuperFetch(`merchant/menu/${id}`, { method: 'DELETE' });
  }
}

export default new Menu();
