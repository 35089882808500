import React, { createRef, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addAcount, actions, selectors } from '../modules/auth';

// import merchantPic from '../assets/profile-pic.png';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  rootPaper: {
    padding: theme.spacing(4),
  },
  accountsGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentAccount: {
    padding: theme.spacing(4),
    border: '1.5px solid #C4C4C4',
    boxShadow: '4px 8px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      flexDirection: 'column',
    },
  },
  otherAccount: {
    padding: theme.spacing(2),
    border: '1px solid #C4C4C4',
    boxShadow: 'none',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      flexDirection: 'column',
    },
  },
  profile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'start',
    // flexGrow: 1,
    // width: '100%',
  },
  profilePicDiv: {
    width: '105px',
    height: '105px',
    overflow: 'hidden',
    borderRadius: '15px',
    marginRight: theme.spacing(4),
    '& > img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
      marginRight: theme.spacing(2),
    },
  },
  merchantName: {
    flexGrow: 1,
  },
  actionButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginTop: theme.spacing(2),
    },
  },
  signButton: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialog: {
    // width: 400,
  },
}));

const formRef = createRef();

export default function SwitchAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const dispatch = useDispatch();
  const { accounts, data, loading } = useSelector(selectors.getAuth);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { register, getValues } = useForm();

  // const watchAllFields = watch();

  // const onSubmit = values => {
  //   console.log(values);
  //   // dispatch(addAcount(values));
  // };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleAddAccount = () => {
    const values = getValues(); // Get input values from react-hooks-form
    dispatch(addAcount(values));
  };

  const handleRemoveAccount = useCallback(
    id => {
      dispatch(actions.removeAccount(id));
    },
    [dispatch],
  );

  const handleSwitchAccount = useCallback(
    data => {
      dispatch(actions.swithAccount(data));
    },
    [dispatch],
  );

  const handleSignOut = () => {
    dispatch(actions.logout());
  };

  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.rootPaper}>
        <div className={classes.header}>
          <Typography variant="h5">My Accounts</Typography>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add Account
          </Button>
        </div>
        <div className={classes.accountsGrid}>
          <Paper className={classes.currentAccount}>
            <div className={classes.profile}>
              <div className={classes.profilePicDiv}>
                <img src={data.profile_picture} alt="" />
              </div>
              <Typography variant="h5" className={classes.merchantName}>
                {data.name}
              </Typography>
            </div>
            <div className={classes.actionButtons}>
              <Button variant="contained" className={classes.signButton} color="primary" disabled>
                Signed In
              </Button>
              <Button
                onClick={handleSignOut}
                variant="outlined"
                className={classes.signButton}
                color="secondary"
              >
                Sign Out
              </Button>
            </div>
          </Paper>
          {accounts &&
            accounts.map(acc => (
              <Paper key={acc.id} className={classes.otherAccount}>
                <div className={classes.profile}>
                  <div className={classes.profilePicDiv}>
                    <img src={acc.profile_picture} alt="" />
                  </div>
                  <Typography variant="h6" className={classes.merchantName}>
                    {acc.name}
                  </Typography>
                </div>
                <div className={classes.actionButtons}>
                  <Button
                    onClick={() => handleSwitchAccount(acc)}
                    variant="contained"
                    className={classes.signButton}
                    color="primary"
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveAccount(acc.id)}
                    className={classes.signButton}
                    color="secondary"
                  >
                    Remove
                  </Button>
                </div>
              </Paper>
            ))}
        </div>
        <Typography variant="h5" align="center" color="primary">
          Contact Support (+880 1631 7242 89), if you are facing any problems.
        </Typography>
      </Paper>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Account</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            Enter your login credentials to add another account (branch)
          </DialogContentText>
          <form ref={formRef} onSubmit={handleAddAccount} className={classes.form}>
            <TextField id="login-id" label="Login ID" inputRef={register} name="username" />
            <TextField
              id="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              inputRef={register}
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Typography variant="subtitle2" gutterBottom align="center" color="secondary">
              {errors.toString()}
            </Typography> */}
            {/* <Button
              // disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign In
            </Button>
            <Button
              // disabled={loading}
              variant="outlined"
              color="error"
              className={classes.button}
            >
              Cancel
            </Button> */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddAccount}
            // form="add-acount"
            // type="submit"
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Add Acount
          </Button>
          <Button onClick={handleClose} disabled={loading} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
