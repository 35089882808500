import React from 'react';

const SvgDrawerOpen = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="fill"
      d="M7.54 125.07h496.91c1.09 0 1.97-.88 1.97-1.97V76.96c0-1.09-.88-1.97-1.97-1.97H7.54c-1.09 0-1.97.88-1.97 1.97v46.14c0 1.09.88 1.97 1.97 1.97zM7.59 282.79h348.79c1.12 0 2.02-.91 2.02-2.02v-46.04c0-1.12-.91-2.02-2.02-2.02H7.59c-1.12 0-2.02.91-2.02 2.02v46.04c0 1.11.91 2.02 2.02 2.02zM7.59 439.7h496.82c1.12 0 2.02-.9 2.02-2.02v-46.04c0-1.12-.9-2.02-2.02-2.02H7.59c-1.12 0-2.02.9-2.02 2.02v46.04c0 1.11.91 2.02 2.02 2.02zM408.71 201.15h-1.35c-3.62 1.24-4.75 3.87-4.74 7.56.07 33.48.04 66.95.04 100.43 0 .45-.01.9.01 1.35.14 2.78 1.97 5.01 4.7 5.54.63.12 1.44.01 2-.3 31.46-17.38 62.9-34.8 94.34-52.22.53-.3 1.04-.75 1.4-1.25 2.17-3.05 1.65-6.52-1.47-8.62-3.04-2.04-6.17-3.97-9.38-5.74-24.72-13.57-49.46-27.1-74.2-40.63-3.77-2.05-7.56-4.08-11.35-6.12z"
    />
  </svg>
);

export default SvgDrawerOpen;
